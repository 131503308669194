import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { ReviewProject } from '../../models3/ReviewProject'
import { PencilButton } from '../utils/Buttons'
import { GenericIcon } from '../utils/Icons'

import './ProjectReview.css'

interface ProjectInfoProps {
    iAmAdmin: boolean
    project: ReviewProject
    setShowGeneralConfigModal: (value: boolean) => void
}

export const ProjectInfo = observer(({ iAmAdmin, project, setShowGeneralConfigModal }: ProjectInfoProps) => {
    const { t } = useTranslation()
    const { projectKey, projectCode, title } = project

    return (
        <div className="project-review-general-config-display">
            <div className="text-input-with-label">
                <GenericIcon
                    iconName="fa-tag"
                    className="project-review-button default-blue-icon icon-spacer fa-fw"
                    tooltip={t('Title')}
                />
                <input value={title} disabled className="icon-spacer" />
            </div>
            <div className="text-input-with-label">
                <GenericIcon
                    iconName="fa-key"
                    className="project-review-button default-blue-icon icon-spacer fa-fw"
                    tooltip={t('projectReviewKey')}
                />
                <input value={projectKey} disabled className="icon-spacer" />
            </div>
            <div className="text-input-with-label">
                <GenericIcon
                    iconName="fa-lock"
                    className="project-review-button default-blue-icon icon-spacer fa-fw"
                    tooltip={t('projectReviewCode')}
                />
                <input value={projectCode} disabled className="icon-spacer" />
            </div>
            <PencilButton
                enabled={iAmAdmin}
                onClick={() => setShowGeneralConfigModal(true)}
                className="project-review-button default-blue-icon"
                tooltip={t('Edit')}
            />
        </div>
    )
})
