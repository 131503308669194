import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { DragDropContext, DropResult, DraggableLocation } from 'react-beautiful-dnd'

import { FilteredPortionPassages } from './StatusEditor'
import TaskColumn from './TaskColumn'
import { ColumnData } from './types'
import { ProjectPlan } from '../../models3/ProjectPlan'
import { Root } from '../../models3/Root'
import { InternalProjectStatus } from '../../types'

interface IStatusBoard {
    rt: Root
    projectPlan: ProjectPlan
    portionPassages: FilteredPortionPassages[]
}

class DragAndDropData {
    @observable columns: ColumnData[]

    constructor(columns: ColumnData[]) {
        this.columns = columns
    }

    findColumn(id: string) {
        return this.columns.find((column) => column.id === id)
    }
}

const createColumns = (plan: ProjectPlan) => {
    const persistedColumns = plan.stages.flatMap((stage) => {
        const columnsForStage: ColumnData[] = stage.tasks.map((task) => {
            return { items: [], id: task.id, task }
        })

        // The "Not started" task is persisted, but "Recorded" is not. It's easier to not persist
        // "Recorded", because we don't want to update the status of each passage to "Recorded"
        // when it has recordings, and move it to "Not started" when the recordings are deleted.
        if (stage.name === InternalProjectStatus.NOT_STARTED) {
            columnsForStage.push({ items: [], id: InternalProjectStatus.RECORDED })
        }

        return columnsForStage
    })
    return persistedColumns
}

const placePassagesInColumns = (columns: ColumnData[], portionPassages: FilteredPortionPassages[]) => {
    const updatedColumns = columns
    portionPassages.forEach((portionPassage) => {
        const { portion } = portionPassage
        portionPassage.passages.forEach((passage) => {
            const item = { passage, portion }
            const isNotStarted = passage.task === InternalProjectStatus.NOT_STARTED || passage.task === ''
            const taskToSearchFor =
                isNotStarted && passage.videosNotDeleted.length > 0 ? InternalProjectStatus.RECORDED : passage.task
            const index = updatedColumns.findIndex((c) => c.id === taskToSearchFor)
            updatedColumns[index >= 0 ? index : 0].items.push(item)
        })
    })

    return updatedColumns
}

function createDataModel(portionPassages: FilteredPortionPassages[], projectPlan: ProjectPlan): DragAndDropData {
    const columns = createColumns(projectPlan)
    const updatedColumns = placePassagesInColumns(columns, portionPassages)
    return new DragAndDropData(updatedColumns)
}

const findPassage = (data: DragAndDropData, columnId: string, draggableId: string) => {
    const column = data.findColumn(columnId)
    if (column !== undefined) {
        const passage = column.items.map((e) => e.passage).find((e) => e._id === draggableId)

        if (passage !== undefined) {
            return passage
        }
    }
}

const updateStatus = (
    data: DragAndDropData,
    source: DraggableLocation,
    destination: DraggableLocation,
    draggableId: string
) => {
    const startId = source.droppableId
    const finishId = destination.droppableId
    if (startId === finishId) {
        return
    }

    const passage = findPassage(data, startId, draggableId)
    if (passage === undefined) {
        return
    }

    const newColumn = data.findColumn(finishId)
    if (newColumn === undefined) {
        return
    }

    const videos = passage.videosNotDeleted
    if (videos.length === 0) {
        return
    }

    const { id } = newColumn

    // The "Recorded" task is not persisted. It's just there to make it easier for users to see
    // which passages have recordings.
    const newStatus = id === InternalProjectStatus.RECORDED ? InternalProjectStatus.NOT_STARTED : id
    videos[videos.length - 1].setStatus(newStatus)
}

const StatusBoard = observer(({ rt, projectPlan, portionPassages }: IStatusBoard) => {
    const data = createDataModel(portionPassages, projectPlan)

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId } = result
        if (!destination) {
            return
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return
        }

        updateStatus(data, source, destination, draggableId)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {data.columns.map(({ id, items, task }) => (
                <TaskColumn key={id} id={id} items={items} task={task} rt={rt} />
            ))}
        </DragDropContext>
    )
})

export default StatusBoard
