import { routePrefix } from '../components/app/slttAvtt'

// This file comes from https://raw.githubusercontent.com/silnrsi/langtags/refs/heads/master/pub/langtags.json
const LANGUAGE_TAGS_PATH = `${routePrefix}/data/langtags.json`

export type LanguageTag = {
    full: string
    iana?: string[]
    iso639_3?: string
    latnnames?: string[]
    localname?: string
    localnames?: string[]
    macrolang?: string
    name: string
    names?: string[]
    nophonvars?: boolean
    obsolete?: boolean
    region?: string
    regionname?: string
    regions?: string[]
    rod?: string
    script: string
    sldr: boolean
    suppress?: boolean
    tag: string
    tags?: string[]
    unwritten?: boolean
    variants?: string[]
    windows?: string
}

type Tag =
    | {
          regions: string[]
          scripts: string[]
          tag: '_conformance'
      }
    | {
          variants: string[]
          tag: '_globalvar'
      }
    | {
          variants: string[]
          tag: '_phonvar'
      }
    | {
          api: string
          date: string
          tag: '_version'
      }
    | LanguageTag

export const fetchLanguageTags = async () => {
    const response = await fetch(LANGUAGE_TAGS_PATH)
    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }

    const jsons = (await response.json()) as Tag[]

    return jsons.filter(
        (tag) =>
            tag.tag !== '_conformance' && tag.tag !== '_globalvar' && tag.tag !== '_phonvar' && tag.tag !== '_version'
    ) as LanguageTag[]
}

export const getFullLanguageName = (languageTag: LanguageTag) => {
    const { name, localname } = languageTag
    return localname && localname.toLowerCase() !== name.toLowerCase() ? `${name} / ${localname}` : name
}

export const languageToOption = (languageTag: LanguageTag) => {
    return {
        label: `${getFullLanguageName(languageTag)} [${languageTag.tag}]`,
        value: languageTag.tag,
        tag: languageTag
    }
}
