import { useRef, useState } from 'react'

import { QRCodeCanvas } from 'qrcode.react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { FileExtension, MimeType } from '../../types'
import { DownloadButton, OKButton } from '../utils/Buttons'
import { downloadWithFilename, safeFileName } from '../utils/Helpers'
import { LoadingIcon } from '../utils/Icons'

interface ProjectReviewModalProps {
    setIsQRModalOpen: (isOpen: boolean) => void
    url: string
    title?: string
}

export const ProjectReviewModal = ({ setIsQRModalOpen, url, title }: ProjectReviewModalProps) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const qrRef = useRef<HTMLCanvasElement | null>(null)

    const handleDownload = () => {
        const canvas = qrRef.current
        if (!canvas) return

        setIsLoading(true)

        try {
            const pngUrl = canvas.toDataURL(MimeType.PNG)
            const fileName = safeFileName(`Engage-${title}-qrcode${FileExtension.PNG}`)

            downloadWithFilename(pngUrl, fileName)
        } catch (error) {
            console.error('Downloading QR Code failed:', error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal show onHide={() => setIsQRModalOpen(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('engageQRCode')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="review-project-qrcode-container">
                    <QRCodeCanvas value={url} size={200} level="M" ref={qrRef} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="button-row">
                    <OKButton
                        enabled
                        onClick={() => setIsQRModalOpen(false)}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                    {isLoading ? (
                        <LoadingIcon className="review-project-qrcode-modal-footer-icons" />
                    ) : (
                        <DownloadButton
                            className="review-project-qrcode-modal-footer-icons review-project-download-button"
                            tooltip={t('Download')}
                            onClick={handleDownload}
                            enabled
                        />
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    )
}
