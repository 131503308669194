import { Project } from '../../models3/Project'

const ProjectProfiles = ({ project }: { project: Project }) => {
    if (!project.projectProfiles) return null

    const sortedProfiles = Object.entries(project.projectProfiles)
        .filter(([key, value]) => !['language', 'profileModBy', 'profileModDate'].includes(key) && value)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))

    return (
        <div>
            {sortedProfiles.map(([key, value]) => (
                <div key={key}>
                    <strong>{key}: </strong> {value}
                </div>
            ))}
        </div>
    )
}

export default ProjectProfiles
