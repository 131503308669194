type LocaleLanguage = {
    code: string
    name: string
}

// Note the order of the languages here, which is the order they appear in the language picker
// We are following order based on Latin alphabet, and then according to user base of AVTT
export const LocaleLanguages: LocaleLanguage[] = [
    { code: 'en', name: 'English' },
    { code: 'id', name: 'Bahasa Indonesia' },
    { code: 'bi', name: 'Bislama' },
    { code: 'es', name: 'Español' },
    { code: 'fr', name: 'Français' },
    { code: 'ha', name: 'Hausa' },
    { code: 'sw', name: 'Kiswahili' },
    { code: 'pt', name: 'Português' },
    { code: 'ru', name: 'Русский' },
    { code: 'tpi', name: 'Tok Pisin' },
    { code: 'hi', name: 'हिंदी' },
    { code: 'th', name: 'ไทย' },
    { code: 'lo', name: 'ພາສາລາວ' },
    { code: 'zh-CN', name: '中文简体' },
    { code: 'ar', name: 'العربية' }
]

export const DEFAULT_LANGUAGE_CODE = LocaleLanguages[0].code
