import { FunctionComponent } from 'react'

import Select from '../select/Select'
import { LocaleLanguages } from '../utils/Languages'

interface ILocaleSelector {
    currentValue: string
    chooseOption: (option: string) => void
}

export const LocaleOptions = () => (
    <>
        {LocaleLanguages.map((item) => {
            const { code, name } = item
            return (
                <option key={code} value={code}>
                    {name}
                </option>
            )
        })}
    </>
)

export const LocaleSelector: FunctionComponent<ILocaleSelector> = ({ currentValue, chooseOption }) => {
    const options = LocaleLanguages.map((item) => ({
        value: item.code,
        label: item.name
    }))

    const selectedOption = options.find((option) => option.value === currentValue)
    return (
        <div>
            <Select
                options={options}
                value={selectedOption}
                onChange={(selected) => {
                    if (selected) {
                        chooseOption(selected.value)
                    }
                }}
                isClearable={false}
                isSearchable={false}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        width: '130px'
                    }),
                    menu: (provided) => ({
                        ...provided,
                        zIndex: 10
                    })
                }}
            />
        </div>
    )
}
