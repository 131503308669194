import React from 'react'

import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'

import './Select.css'

export interface SelectCreatableOption {
    value: string
    label: string
}

interface SelectCreatableProps {
    value: SelectCreatableOption | null
    onChange: (selectedOption: SelectCreatableOption | null) => void
    options: SelectCreatableOption[]
    placeholder?: string
    isDisabled?: boolean
}

const SelectCreatable: React.FC<SelectCreatableProps> = ({
    value,
    onChange,
    options,
    isDisabled = false,
    placeholder
}) => {
    const { t } = useTranslation()

    return (
        <CreatableSelect
            isClearable
            value={value}
            onChange={onChange}
            options={options}
            placeholder={placeholder ?? t('selectOneOrAdd')}
            isDisabled={isDisabled}
            classNamePrefix="select-prefix"
        />
    )
}

export default SelectCreatable
