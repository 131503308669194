import { FC, useState, useEffect, useMemo } from 'react'

import { observer } from 'mobx-react'
import { Col, Row, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import ProjectProfiles from './ProjectProfiles'
import ProjectRecordingInfo from './ProjectRecordingInfo'
import { ProjectsEditorCSVExportButton } from './ProjectsEditorCSVExportButton'
import useFetchLanguageTags from '../../hooks/useFetchLanguageTags'
import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'
import { languageToOption } from '../../resources/LanguageTags'
import { useOnlineStatus } from '../app/OnlineStatusContext'
import { useAppRoot } from '../app/RootContext'
import { ProjectAddModal } from '../modals/project/AddModal'
import { ProjectDeleteModal } from '../modals/project/DeleteModal'
import { ProjectProfilesModal } from '../modals/project/ProjectProfilesModal'
import MembersByRoleView from '../projectSettings/members/MembersByRoleView'
import { LanguageSelector } from '../projectSettings/preferences/LanguageSelector'
import { SelectCreatableOption } from '../select/SelectCreatable'
import { AddButton, DeleteButton, EditButton } from '../utils/Buttons'
import { GenericIcon, LoadingIcon } from '../utils/Icons'

import './ProjectsEditor.css'

interface ProjectsEditorProps {
    groupRoot?: Root
}

const extractUniqueOptions = (projects: Root[], attribute: string): { value: string; label: string }[] => {
    const uniqueValues = new Set<string>()
    projects.forEach((rt) => {
        if (rt.project.projectProfiles?.[attribute]) {
            uniqueValues.add(rt.project.projectProfiles[attribute] as string)
        }
    })
    return Array.from(uniqueValues).map((value) => ({ value, label: value }))
}

const ProjectsEditor: FC<ProjectsEditorProps> = observer(({ groupRoot }) => {
    const appRoot = useAppRoot()
    const { t } = useTranslation()
    const { isOnline } = useOnlineStatus()
    const { languageTags } = useFetchLanguageTags()

    const [isProjectAddModalOpen, setIsProjectAddModalOpen] = useState(false)
    const [openProjectDeleteModal, setOpenProjectDeleteModal] = useState<Root>()
    const [openProjectProfilesModal, setOpenProjectProfilesModal] = useState<Project>()

    const [loading, setLoading] = useState(false)
    const [projectSearchQuery, setProjectSearchQuery] = useState<string>('')

    const [statusOptions, setStatusOptions] = useState<SelectCreatableOption[]>([])
    const [partnerOptions, setPartnerOptions] = useState<SelectCreatableOption[]>([])
    const [countryOptions, setCountryOptions] = useState<SelectCreatableOption[]>([])

    const isGroupAdmin = groupRoot?.iAmAdmin ?? false

    const languageTagsMap = useMemo(() => {
        const map = new Map<string, string>()
        languageTags.forEach((tag) => {
            const option = languageToOption(tag)
            map.set(option.value, option.label.split('[')[0].trim())
        })
        return map
    }, [languageTags])

    const allProjects = useMemo(() => {
        return groupRoot
            ? appRoot.rts
                  .filter(({ project }) => groupRoot.groupProjects?.includes(project.name))
                  .sort(appRoot.sortRootProjects)
            : appRoot.getAllProjectRootsSorted().filter(({ project }) => {
                  const query = projectSearchQuery.toLowerCase()
                  const languageCode = project.language || project.projectProfiles?.language || ''
                  const languageFullname = languageTagsMap.get(languageCode) || ''

                  return [
                      project.getFormattedDisplayName(),
                      project.projectProfiles?.country,
                      project.projectProfiles?.partner,
                      project.projectProfiles?.status,
                      project.projectProfiles?.notes,
                      languageCode,
                      languageFullname
                  ].some((field) => field?.toLowerCase().includes(query))
              })
    }, [appRoot, groupRoot, projectSearchQuery, languageTagsMap])

    useEffect(() => {
        setStatusOptions(extractUniqueOptions(allProjects, 'status'))
        setPartnerOptions(extractUniqueOptions(allProjects, 'partner'))
        setCountryOptions(extractUniqueOptions(allProjects, 'country'))
    }, [allProjects])

    useEffect(() => {
        let mounted = true

        async function initializeRoots() {
            setLoading(true)
            await Promise.all(allProjects.map((rt) => rt.initialize()))
            if (mounted) setLoading(false)
        }

        // do not initialize if we are displaying all projects
        if (groupRoot) {
            initializeRoots()
        }

        return () => {
            mounted = false
        }
    }, [allProjects, groupRoot])

    if (loading) {
        return <LoadingIcon className="" />
    }

    const reloadPage = () => {
        // This only works sometimes when not in a timeout. My guess is there is some
        // kind of race condition.
        setTimeout(() => {
            window.location.reload()
        }, 0)
    }

    const handleLanguageChange = (rt: Root, newLanguage: string) => {
        // create a project doc entry
        rt.project.setLanguage(newLanguage)

        // update the project attributes so the result can be seen without a refresh
        rt.project.projectProfiles = {
            ...rt.project.projectProfiles,
            language: newLanguage
        }
    }

    const handleProfilesUpdate = () => {
        setStatusOptions(extractUniqueOptions(allProjects, 'status'))
        setPartnerOptions(extractUniqueOptions(allProjects, 'partner'))
        setCountryOptions(extractUniqueOptions(allProjects, 'country'))
    }

    return (
        <div>
            {openProjectProfilesModal && (
                <ProjectProfilesModal
                    project={openProjectProfilesModal}
                    statusOptions={statusOptions}
                    partnerOptions={partnerOptions}
                    countryOptions={countryOptions}
                    onProfilesUpdate={handleProfilesUpdate}
                    closeModal={() => setOpenProjectProfilesModal(undefined)}
                />
            )}
            {openProjectDeleteModal && (
                <ProjectDeleteModal closeModal={reloadPage} projectRoot={openProjectDeleteModal} />
            )}
            {isProjectAddModalOpen && <ProjectAddModal closeModal={reloadPage} groupRoot={groupRoot} />}
            <div className="container-fluid">
                <div className="project-heading-row">
                    <h3>{groupRoot ? t('Group Projects') : t('All Projects')}</h3>
                    {groupRoot && (
                        <ProjectsEditorCSVExportButton languageTagsMap={languageTagsMap} groupRoot={groupRoot} />
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-auto">
                        {!groupRoot && (
                            <div className="bibles-table-search-input__container">
                                <GenericIcon
                                    className="bibles-table-search-input__icon"
                                    iconName="fa-search"
                                    tooltip={t('searchPublishedBibles')}
                                />
                                <input
                                    onChange={(e) => setProjectSearchQuery(e.target.value)}
                                    type="text"
                                    value={projectSearchQuery}
                                />
                                <ProjectsEditorCSVExportButton languageTagsMap={languageTagsMap} />
                            </div>
                        )}
                        <Table striped className={!groupRoot ? 'projects-table' : ''}>
                            <thead>
                                <tr>
                                    <th>{t('Project')}</th>
                                    <th>{t('projectLanguage')}</th>
                                    <th>{t('passageRecording')}</th>
                                    {groupRoot && <th>{t('Admin Users')}</th>}
                                    {!groupRoot && <th>{t('projectProfilesLabel')}</th>}
                                    <th style={{ width: '150px' }} />
                                </tr>
                            </thead>
                            <tbody>
                                {allProjects.map((rt) => (
                                    <tr key={rt.project.name}>
                                        <td>
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                {groupRoot
                                                    ? rt.project.getFormattedDisplayName()
                                                    : `${
                                                          rt.group ? `${rt.group}\n  ` : ''
                                                      }${rt.project.getFormattedDisplayName()}`}
                                            </div>
                                        </td>
                                        <td>
                                            <LanguageSelector
                                                classNamePrefix="project-language-selector"
                                                languageCode={
                                                    rt.project.language || rt.project.projectProfiles?.language || ''
                                                }
                                                setLanguageCode={(newLanguage) => handleLanguageChange(rt, newLanguage)}
                                                languageTags={languageTags}
                                                selectStyle="auto"
                                                enabled={rt.iAmAdmin}
                                            />
                                        </td>
                                        <td>
                                            <ProjectRecordingInfo project={rt.project} />
                                        </td>
                                        {groupRoot && (
                                            <td>
                                                <MembersByRoleView project={rt.project} includeRoles={['admin']} />
                                            </td>
                                        )}
                                        {!groupRoot && (
                                            <td>
                                                <ProjectProfiles project={rt.project} />
                                            </td>
                                        )}
                                        {!appRoot.useMobileLayout && (appRoot.iAmRoot || isGroupAdmin) && (
                                            <td>
                                                <Row className="no-gutters">
                                                    {appRoot.iAmRoot && !groupRoot && (
                                                        <Col>
                                                            <EditButton
                                                                onClick={() => setOpenProjectProfilesModal(rt.project)}
                                                                className="sl-delete-button"
                                                                tooltip={t('Edit')}
                                                                enabled={isOnline}
                                                            />
                                                        </Col>
                                                    )}
                                                    <Col>
                                                        <DeleteButton
                                                            onClick={() => setOpenProjectDeleteModal(rt)}
                                                            className="sl-delete-button"
                                                            tooltip={t('Delete Project')}
                                                            enabled={isOnline}
                                                        />
                                                    </Col>
                                                </Row>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                                {!appRoot.useMobileLayout && (appRoot.iAmRoot || isGroupAdmin) && (
                                    <tr>
                                        <td colSpan={5}>
                                            <AddButton
                                                onClick={() => setIsProjectAddModalOpen(true)}
                                                className="project-plan-copy-button"
                                                buttonClassName=""
                                                tooltip={t('Add project')}
                                                enabled={isOnline}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ProjectsEditor
