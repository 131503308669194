import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ReviewRespondent } from './ReviewRespondent'
import { ReviewComment, useReviewComments } from '../../../hooks/useReviewComments'
import { IDateFormatter } from '../../../models3/DateUtilities'
import { ReviewProject } from '../../../models3/ReviewProject'
import { ReviewProjectGeneralQuestion } from '../../../models3/ReviewProjectGeneralQuestion'
import { ReviewRecordingMediaPlayer } from '../../audio/ReviewRecordingMediaPlayer'
import { LoadingIcon } from '../../utils/Icons'

import './ReviewResponses.css'

const RESPONDENT_COLUMN_WIDTH_PX = 200
const DATE_COLUMN_WIDTH_PX = 125

const RespondentComment = ({ comment, projectName }: { comment: ReviewComment; projectName: string }) => {
    return (
        <div>
            <div>{comment.content}</div>
            {comment.fileName && <ReviewRecordingMediaPlayer avttProjectName={projectName} url={comment.fileName} />}
        </div>
    )
}

type CommentsListProps = {
    comments: ReviewComment[]
    dateFormatter: IDateFormatter
    reviewProject: ReviewProject
    projectName: string
}

const CommentsList = ({ comments, dateFormatter, reviewProject, projectName }: CommentsListProps) => {
    return (
        <>
            {comments.map((comment) => (
                <tr key={comment.id}>
                    <td width={DATE_COLUMN_WIDTH_PX}>{dateFormatter.format(new Date(comment.createdAt))}</td>
                    <td width={RESPONDENT_COLUMN_WIDTH_PX}>
                        <ReviewRespondent comment={comment} reviewProject={reviewProject} projectName={projectName} />
                    </td>
                    <td>
                        <RespondentComment comment={comment} projectName={projectName} />
                    </td>
                </tr>
            ))}
        </>
    )
}

type QuestionCommentsProps = {
    projectName: string
    reviewPassageId: string
    reviewQuestion: ReviewProjectGeneralQuestion
    dateFormatter: IDateFormatter
    reviewProject: ReviewProject
}

const QuestionComments = ({
    projectName,
    reviewPassageId,
    reviewQuestion,
    dateFormatter,
    reviewProject
}: QuestionCommentsProps) => {
    const { t } = useTranslation()
    const { comments, isLoading, isError } = useReviewComments({
        projectName,
        reviewPassageId,
        reviewQuestionId: reviewQuestion._id
    })

    if (isError) {
        return (
            <tr>
                <td width={RESPONDENT_COLUMN_WIDTH_PX}>{t('reviewQuestionFetchError')}</td>
                <td colSpan={2} />
            </tr>
        )
    }

    if (isLoading) {
        return (
            <tr>
                <td width={RESPONDENT_COLUMN_WIDTH_PX}>
                    <LoadingIcon className="" />
                </td>
                <td colSpan={2} />
            </tr>
        )
    }

    if (comments.length === 0) {
        return (
            <tr>
                <td width={RESPONDENT_COLUMN_WIDTH_PX}>{t('reviewQuestionNoComments')}</td>
                <td colSpan={2} />
            </tr>
        )
    }

    return (
        <CommentsList
            comments={comments}
            dateFormatter={dateFormatter}
            reviewProject={reviewProject}
            projectName={projectName}
        />
    )
}

const QuestionWithComments = ({
    projectName,
    reviewPassageId,
    reviewQuestion,
    dateFormatter,
    reviewProject
}: {
    projectName: string
    reviewPassageId: string
    reviewQuestion: ReviewProjectGeneralQuestion
    dateFormatter: IDateFormatter
    reviewProject: ReviewProject
}) => {
    // TODO Much later:
    // - allow deletion of comments (mark for deletion)
    // - only show non-deleted comments
    // - allow "saving" comments to caches, and show them first

    return (
        <Table striped className="comments-table">
            <thead>
                <tr className="comments-table-header">
                    <th>{reviewQuestion.text}</th>
                    <th colSpan={2}>
                        {reviewQuestion.fileName && (
                            <div>
                                <ReviewRecordingMediaPlayer
                                    avttProjectName={projectName}
                                    url={reviewQuestion.fileName}
                                />
                            </div>
                        )}
                    </th>
                </tr>
            </thead>
            <tbody>
                <QuestionComments
                    projectName={projectName}
                    reviewPassageId={reviewPassageId}
                    reviewQuestion={reviewQuestion}
                    dateFormatter={dateFormatter}
                    reviewProject={reviewProject}
                />
            </tbody>
        </Table>
    )
}

export const CommentsTable = ({
    projectName,
    reviewPassageId,
    reviewQuestions,
    dateFormatter,
    reviewProject
}: {
    projectName: string
    reviewPassageId: string
    reviewQuestions: ReviewProjectGeneralQuestion[]
    dateFormatter: IDateFormatter
    reviewProject: ReviewProject
}) => {
    const { t } = useTranslation()
    if (!reviewQuestions.length) {
        return <div>{t('reviewNoQuestions')}</div>
    }

    return (
        <div>
            {reviewQuestions.map((reviewQuestion) => (
                <QuestionWithComments
                    key={reviewQuestion._id}
                    projectName={projectName}
                    reviewPassageId={reviewPassageId}
                    reviewQuestion={reviewQuestion}
                    dateFormatter={dateFormatter}
                    reviewProject={reviewProject}
                />
            ))}
        </div>
    )
}
