import { FC, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { LanguageSelector } from './LanguageSelector'
import { NoteMarkerTypePreferences } from './NoteMarkerTypePreferences'
import { RecordingLayoutPreferences } from './RecordingLayoutPreferences'
import { RecordingPreferences } from './RecordingPreferences'
import { SegmentStatusPreferences } from './SegmentStatusPreferences'
import { VideoCompressionPreferences } from './VideoCompressionPreferences'
import useFetchLanguageTags from '../../../hooks/useFetchLanguageTags'
import { Project } from '../../../models3/Project'
import { Root } from '../../../models3/Root'
import { DateFormat } from '../../../types'
import Select from '../../select/Select'
import { EditButton } from '../../utils/Buttons'
import { displayError } from '../../utils/Errors'
import { isValidDisplayNameCharacters, isValidDisplayNameLength } from '../../utils/Helpers'
import { GenericIcon } from '../../utils/Icons'
import { ILocalizedChoice, MakeLocalizedChoice } from '../../utils/MakeLocalizedChoice'
import { EditableRichText } from '../../utils/RichTextEditor'
import { Switch } from '../../utils/Switch'
import TextInput from '../../utils/TextInput'

import '../ProjectSettings.css'

type ProjectPreferencesProps = {
    rt: Root
}

const iconClassName = 'project-preferences-icon fa-fw'

type DisplayNameProps = {
    allowEditing: boolean
    displayName: string
    setDisplayName: (displayName: string) => void
}

const DisplayName: FC<DisplayNameProps> = observer(({ allowEditing, displayName, setDisplayName }) => {
    const { t } = useTranslation()
    const [editing, setEditing] = useState(false)

    function validate(value: string) {
        const newName = value.trim()
        if (!isValidDisplayNameCharacters(newName)) {
            return t('Invalid characters')
        }

        if (!isValidDisplayNameLength(newName)) {
            return t('Too short or long')
        }

        return ''
    }

    return (
        <div className="team-preference-option">
            <GenericIcon className={iconClassName} iconName="fa-flag" tooltip={t('Project Name')} />
            {editing ? (
                <TextInput
                    validate={validate}
                    initialValue={displayName}
                    message={t('Type Enter to change project name or type Esc to cancel')}
                    _onEnter={(name) => {
                        if (!validate(name)) {
                            setDisplayName(name)
                        }
                        setEditing(false)
                    }}
                    _onEscape={() => setEditing(false)}
                    allowEmptyValue={false}
                />
            ) : (
                <div className="project-display-name right-margin-15px">{displayName}</div>
            )}
            {!editing && (
                <EditButton
                    enabled={allowEditing}
                    onClick={() => setEditing(true)}
                    className="project-preferences-edit-button"
                    tooltip={t('Edit')}
                />
            )}
        </div>
    )
})

type ProjectTextProps = {
    allowEditing: boolean
    text: string
    setText: (value: string) => void
}

const ProjectText: FC<ProjectTextProps> = observer(({ allowEditing, text, setText }) => {
    const { t } = useTranslation()
    const [editing, setEditing] = useState(false)

    const prefix = 'project-text'
    return (
        <div className="team-preference-option project-description-wrapper">
            <GenericIcon className={iconClassName} iconName="fa-comment-alt" tooltip={t('Project description')} />
            <EditableRichText
                savedText={text}
                save={setText}
                cancel={() => {}}
                editorOpen={editing}
                setEditorOpen={setEditing}
                prefix={prefix}
            />
            {!editing && (
                <EditButton
                    enabled={allowEditing}
                    onClick={() => setEditing(true)}
                    className="project-preferences-edit-button"
                    tooltip={t('Edit')}
                />
            )}
        </div>
    )
})

type IProjectType = {
    allowEditing: boolean
    project: Project
}

const ProjectType: FC<IProjectType> = ({ allowEditing, project }) => {
    const { t } = useTranslation()

    const [projectType, setProjectType] = useState(project.projectType)

    const projectTypes: ILocalizedChoice[] = [
        {
            choice: 'translation',
            localizedChoice: t('Translation Project'),
            title: ''
        },
        {
            choice: 'additional',
            localizedChoice: t('Additional Translation Project'),
            title: t(`When a team has multiple projects mark all but the first as 'Additional'`)
        },
        {
            choice: 'test_training',
            localizedChoice: t('Test or Training Project'),
            title: ''
        },
        {
            choice: 'resource',
            localizedChoice: t('Translation resource'),
            title: ''
        },
        {
            choice: 'other',
            localizedChoice: t('Other Project'),
            title: t('Discussion forum, resource development, etc.')
        }
    ]
    const choice = projectTypes.find((pt) => pt.choice === projectType) ?? projectTypes[0]

    return (
        <div className="team-preference-option">
            <GenericIcon className={iconClassName} iconName="fa-certificate" tooltip={t('Project type')} />
            {allowEditing ? (
                <MakeLocalizedChoice
                    choices={projectTypes}
                    choice={projectType}
                    setChoice={(_choice) => {
                        project.setProjectType(_choice).catch(displayError)
                        setProjectType(_choice as any)
                    }}
                />
            ) : (
                <span>{choice.localizedChoice}</span>
            )}
        </div>
    )
}

interface IDateFormatSelector {
    dateFormat: DateFormat
    setDateFormat: (format: DateFormat) => void
}

const DateFormatSelector: FC<IDateFormatSelector> = observer(({ dateFormat, setDateFormat }) => {
    const { t } = useTranslation()
    const values = Object.values(DateFormat)

    const options = values.map((value) => ({
        value,
        label: value
    }))

    const selectedOption = options.find((option) => option.value === dateFormat)

    return (
        <div className="team-preference-option">
            <GenericIcon className={iconClassName} iconName="fa-calendar-alt" tooltip={t('Date format')} />
            <Select
                options={options}
                value={selectedOption}
                onChange={(option) => {
                    if (option) {
                        setDateFormat(option.value)
                    }
                }}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        width: '165px'
                    })
                }}
                isClearable={false}
                isSearchable={false}
            />
        </div>
    )
})

interface ProjectLanguageSelectorProps {
    languageCode: string
    setLanguageCode: (value: string) => void
    enabled?: boolean
}

const ProjectLanguageSelector = ({ languageCode, setLanguageCode, enabled = true }: ProjectLanguageSelectorProps) => {
    const { t } = useTranslation()
    const { languageTags } = useFetchLanguageTags()

    return (
        <div className="team-preference-option">
            <GenericIcon className={iconClassName} iconName="fa-language" tooltip={t('projectLanguage')} />
            <LanguageSelector
                languageCode={languageCode}
                setLanguageCode={setLanguageCode}
                languageTags={languageTags}
                enabled={enabled}
            />
        </div>
    )
}

const ProjectPreferences: FC<ProjectPreferencesProps> = observer(({ rt }) => {
    const { t } = useTranslation()
    const { iAmAdmin, iAmTranslator, iAmRoot, project } = rt
    const {
        displayName,
        setDisplayName,
        text,
        setText,
        dateFormat,
        setDateFormat,
        recordingCountdown,
        setRecordingCountdown,
        autoGainControl,
        setAutoGainControl,
        language,
        setLanguage
    } = project

    const allowEditing = iAmAdmin

    return (
        <div className="project-preferences-tab">
            <h3>{t('Project Preferences')}</h3>
            <DisplayName {...{ displayName, setDisplayName, allowEditing: allowEditing && !rt.groupProjects }} />
            <ProjectText {...{ allowEditing, text, setText }} />
            <ProjectType {...{ project, allowEditing }} />
            <ProjectLanguageSelector languageCode={language} setLanguageCode={setLanguage} enabled={iAmTranslator} />
            {allowEditing && (
                <>
                    <DateFormatSelector {...{ dateFormat, setDateFormat }} />
                    <NoteMarkerTypePreferences project={project} iconClassName={iconClassName} />
                    <SegmentStatusPreferences project={project} iconClassName={iconClassName} />
                </>
            )}
            {iAmTranslator && (
                <RecordingPreferences
                    iconClassName={iconClassName}
                    recordingCountdown={recordingCountdown}
                    setRecordingCountdown={setRecordingCountdown}
                    autoGainControl={autoGainControl}
                    setAutoGainControl={setAutoGainControl}
                />
            )}
            {allowEditing && (
                <>
                    <VideoCompressionPreferences project={project} iconClassName={iconClassName} />
                    <RecordingLayoutPreferences project={project} iconClassName={iconClassName} />
                    {iAmRoot && (
                        <div className="project-preferences-switch">
                            <Switch
                                value={project.isEngageEnabled}
                                setValue={(value) => project.setIsEngageEnabled(value)}
                                tooltip=""
                            >
                                <GenericIcon
                                    className={iconClassName}
                                    iconName="fa-comments"
                                    tooltip={t('projectReviewTitle')}
                                />
                            </Switch>
                        </div>
                    )}
                </>
            )}
        </div>
    )
})

export default ProjectPreferences
