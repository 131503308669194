/* eslint-disable react/prefer-stateless-function */

// This component controls the recording of videos.
// It displays the video while the recording is happening.
// It pushes video data blobs to videoUploader.
// It calls videoUploader.onRecordingDone when recording is complete.

import { EventEmitter } from 'events'

import { Component } from 'react'

import { t } from 'i18next'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { delay } from 'q'

import { verifyBlobs } from './VideoUploader'
import { AudioContextFactory } from './WaveformVisualizer'
import { displayError, RecordingNotAllowedByBrowserErrorMessage } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import { appendSilenceToEndOfRecording } from '../utils/Opus'

import './Video.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoRecorder')

const beep = (durationMs: number, frequencyHz: number, volume: number): Promise<boolean> => {
    const audioContext = AudioContextFactory.getAudioContext()
    return new Promise((resolve, reject) => {
        try {
            const oscillatorNode = audioContext.createOscillator()
            const gainNode = audioContext.createGain()
            oscillatorNode.connect(gainNode)
            oscillatorNode.frequency.value = frequencyHz
            oscillatorNode.type = 'sine'
            gainNode.connect(audioContext.destination)

            gainNode.gain.value = volume * 0.01

            oscillatorNode.start(audioContext.currentTime)
            oscillatorNode.stop(audioContext.currentTime + durationMs * 0.001)
            oscillatorNode.onended = () => {
                resolve(true)
            }
        } catch (error) {
            reject(error)
        }
    })
}

const beepAndWait = async () => {
    const totalDuration = 1000
    const beepDuration = 250
    const volume = 25
    const C5_FREQUENCY = 523.25
    await beep(beepDuration, C5_FREQUENCY, volume)
    await delay(totalDuration - beepDuration)
}

const setupCountdown = async (
    setRecordingState: (state: AVTTRecordingState) => void,
    recordingCountdown?: number
): Promise<void> => {
    const countdown = recordingCountdown || 0

    if (countdown >= 3) {
        setRecordingState('RECORDING_IN_THREE_SECONDS')
        await beepAndWait()
    }

    if (countdown >= 2) {
        setRecordingState('RECORDING_IN_TWO_SECONDS')
        await beepAndWait()
    }

    if (countdown >= 1) {
        setRecordingState('RECORDING_IN_ONE_SECOND')
        await beepAndWait()
    }
}

interface IVideoRecorder {
    onRecordingDone: (blob: Blob) => void
    onRecordingError: (err: any) => void
    // The video uploader is passed to us from our parent as a tricky (?!) way to allow
    // the parent to create a new item of an appropriate type when the recording is complete.

    usePauseAndResume?: boolean
    setRecordingState?: (state: AVTTRecordingState) => void
    setMediaStream?: (stream: MediaStream) => void
    recordingCountdown?: number
    appendSilenceToEnd?: boolean
    autoGainControl?: boolean
}

export type AVTTRecordingState =
    | 'NOT_INITIALIZED'
    | 'INITIALIZED'
    | 'RECORDING_IN_THREE_SECONDS'
    | 'RECORDING_IN_TWO_SECONDS'
    | 'RECORDING_IN_ONE_SECOND'
    | 'RECORDING'
    | 'PAUSED'
    | 'STOPPED'

@observer
export default class VideoRecorder extends Component<IVideoRecorder> {
    private vc: any

    private mediaRecorder: MediaRecorder | null = null

    @observable recordingState: AVTTRecordingState = 'NOT_INITIALIZED'

    blobs: Blob[] = []

    cancelled = false

    mediaStream: MediaStream | null = null

    constructor(props: IVideoRecorder) {
        super(props)

        this.errorStop = this.errorStop.bind(this)
        this.stop = this.stop.bind(this)
        this.setupVideoAndAudio = this.setupVideoAndAudio.bind(this)
        this.startRecording = this.startRecording.bind(this)
        this.pause = this.pause.bind(this)
        this.resume = this.resume.bind(this)
    }

    componentDidMount() {
        const _record = async () => {
            try {
                await this.setupVideoAndAudio()
                await this.startRecordingAfterCountdown()
            } catch (err) {
                this.errorStop(err)
            }
        }

        setTimeout(_record, 1000)
    }

    componentWillUnmount() {
        const { mediaRecorder, mediaStream } = this
        const { setRecordingState } = this.props

        if (mediaRecorder) {
            const { state } = mediaRecorder
            if (state === 'recording' || state === 'paused') {
                this.cancel()
            }
        }

        // Release camera and microphone
        if (mediaStream) {
            const tracks = mediaStream.getTracks()
            for (const track of tracks) {
                track.stop()
            }
        }

        setRecordingState?.('NOT_INITIALIZED') // reset recording state
    }

    setRecordingState = (state: AVTTRecordingState) => {
        const { setRecordingState } = this.props
        log('setRecordingState', state)
        this.recordingState = state
        setRecordingState?.(state)
    }

    async setupVideoAndAudio() {
        this.setRecordingState('NOT_INITIALIZED')
        const { setMediaStream, autoGainControl } = this.props

        try {
            // let frameRate = API.idealFrameRate(videoUploader.projectName)

            log('initializing media stream')

            this.mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    autoGainControl
                },
                video: {
                    // width: { ideal: 1280 },
                    height: { ideal: 480 }
                    // frameRate: { ideal: frameRate },
                }
            })

            setMediaStream?.(this.mediaStream)

            this.vc.srcObject = this.mediaStream
            this.vc.volume = 0.0 // prevent feedback

            this.setRecordingState('INITIALIZED')
        } catch (err) {
            this.errorStop(err)
        }
    }

    async startRecordingAfterCountdown() {
        const { recordingCountdown } = this.props

        await setupCountdown(this.setRecordingState.bind(this), recordingCountdown)
        await this.startRecording()
    }

    async startRecording() {
        try {
            // let frameRate = API.idealFrameRate(videoUploader.projectName)

            if (!this.mediaStream) {
                log('### startRecording failed, no mediaStream')
                return
            }

            log('startRecording')

            const mediaRecorder = new MediaRecorder(this.mediaStream)
            this.mediaRecorder = mediaRecorder

            mediaRecorder.ondataavailable = this.dataAvailable.bind(this)

            mediaRecorder.start(10000)
            this.setRecordingState('RECORDING')
        } catch (err) {
            this.errorStop(err)
        }
    }

    // This is an event handler for mediaRecorder
    async dataAvailable(event: any) {
        const {
            cancelled,
            mediaRecorder,
            props: { onRecordingDone }
        } = this

        // If the mediaRecorder is not active then there will be no more blobs
        const lastBlob = this.mediaRecorder?.state === 'inactive'

        log(
            'dataAvailable',
            fmt({
                state: mediaRecorder?.state,
                lastBlob,
                cancelled
            })
        )

        if (cancelled) return

        this.blobs.push(event.data)
        if (lastBlob) {
            const result = await verifyBlobs(this.blobs)
            if (result.type === 'error') {
                this.errorStop(t('videoUploaderInvalidBlobError'))
                return
            }
            onRecordingDone(result.blob)
        }
    }

    errorStop(err: any) {
        const { onRecordingError } = this.props
        log(`errorStop`, err)

        if (!this.cancelled) {
            if (err.name === 'NotAllowedError') {
                displayError(err, undefined, <RecordingNotAllowedByBrowserErrorMessage />)
            } else {
                displayError(err)
            }
            onRecordingError(err)
        }
        this.stop()
    }

    // Only called when user has explictily requeted that video recording be permanently
    // stopped.
    // Should not be called when recording has been paused.
    // May be invoked by this control or externally.
    stop() {
        log('stop')
        const {
            mediaRecorder,
            mediaStream,
            props: { onRecordingError }
        } = this

        try {
            if (mediaRecorder) {
                // state is inactive/recording/paused
                const { state } = mediaRecorder
                if (state === 'recording' || state === 'paused') {
                    mediaRecorder.stop()
                }
            }

            // release camera and microphone
            if (mediaStream) {
                const tracks = mediaStream.getTracks()
                for (const track of tracks) {
                    track.stop()
                }
            }
            this.setRecordingState('STOPPED')
        } catch (err) {
            if (!this.cancelled) {
                onRecordingError(err)
            }

            // release camera and microphone
            if (mediaStream) {
                const tracks = mediaStream.getTracks()
                for (const track of tracks) {
                    track.stop()
                }
            }
        }
    }

    pause() {
        log('pause')
        const { mediaRecorder } = this

        if (mediaRecorder === null) {
            log('### mediaRecorder not set, PAUSE action skiped')
            return
        }

        try {
            this.setRecordingState('PAUSED')
            mediaRecorder.pause()
        } catch (err) {
            console.log(err)
        }
    }

    resume() {
        log('resume')
        const { mediaRecorder } = this

        if (mediaRecorder === null) {
            log('### mediaRecorder not set, RESUME action skiped')
            return
        }

        try {
            mediaRecorder.resume()
            this.setRecordingState('RECORDING')
        } catch (err) {
            console.log('### resume failed', err)
        }
    }

    cancel() {
        log('cancel')
        this.cancelled = true
        this.stop()
    }

    render() {
        const { recordingState } = this

        const watermarkText = recordingState === 'PAUSED' ? t('Paused') : ''

        let countdown = ''
        if (recordingState === 'RECORDING_IN_THREE_SECONDS') {
            countdown = '3'
        } else if (recordingState === 'RECORDING_IN_TWO_SECONDS') {
            countdown = '2'
        } else if (recordingState === 'RECORDING_IN_ONE_SECOND') {
            countdown = '1'
        }

        return (
            <div className="video-recording-area">
                {watermarkText && (
                    <div className="video-recording-area-message-wrapper">
                        <div className="video-recording-area-message">{watermarkText}</div>
                    </div>
                )}
                {countdown && (
                    <div className="video-recording-countdown-wrapper">
                        <div className="video-recording-area-message">{countdown}</div>
                    </div>
                )}
                <video
                    className="video-recorder video-border"
                    ref={(vc) => {
                        this.vc = vc
                    }}
                    autoPlay
                />
            </div>
        )
    }
}

// Events:
// onRecordingDone: () => void,
// onError: (err: any) => void,
interface AudioRecorderProps {
    recordingCountdown?: number
    onRecordingStateChange?: (state: AVTTRecordingState) => void
    setMediaStream?: (stream: MediaStream) => void
    autoGainControl?: boolean
}

export class AudioRecorder extends EventEmitter {
    blobs: Blob[] = []

    cancelled = false

    recordingCountdown?: number

    autoGainControl?: boolean

    @observable recordingState: AVTTRecordingState = 'NOT_INITIALIZED'

    @observable mediaStream?: MediaStream

    mediaRecorder?: MediaRecorder

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRecordingStateChange?: (state: AVTTRecordingState) => void

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setMediaStream?: (stream: MediaStream) => void

    constructor({ recordingCountdown, onRecordingStateChange, setMediaStream, autoGainControl }: AudioRecorderProps) {
        super()
        this.recordingCountdown = recordingCountdown
        this.autoGainControl = autoGainControl
        this.onRecordingStateChange = onRecordingStateChange
        this.setMediaStream = setMediaStream
    }

    setRecordingState(state: AVTTRecordingState) {
        this.recordingState = state
        this.onRecordingStateChange?.(state)
    }

    async _record() {
        try {
            await this.setupAudio()
            await setupCountdown(this.setRecordingState.bind(this), this.recordingCountdown)
            this.startRecording()
        } catch (err) {
            this.errorStop(err)
            this.releaseTracks()
        }
    }

    async setupAudio() {
        this.setRecordingState('NOT_INITIALIZED')

        try {
            const _mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    autoGainControl: this.autoGainControl
                },
                video: false
            })

            this.mediaStream = _mediaStream
            this.setMediaStream?.(_mediaStream)
            this.setRecordingState('INITIALIZED')
        } catch (err) {
            this.errorStop(err)
            this.releaseTracks()
        }
    }

    async startRecording() {
        if (!this.mediaStream) {
            log('### startRecording failed, no mediaStream')
            return
        }

        log('startRecording')

        const mediaRecorder = new MediaRecorder(this.mediaStream)
        this.mediaRecorder = mediaRecorder

        mediaRecorder.ondataavailable = this.onDataAvailable.bind(this)
        mediaRecorder.start(10000)
        this.setRecordingState('RECORDING')
    }

    async onDataAvailable(event: any) {
        const lastBlob = this.mediaRecorder?.state === 'inactive'

        log(
            'dataAvailable',
            fmt({
                state: this.mediaRecorder?.state,
                lastBlob,
                cancelled: this.cancelled
            })
        )

        if (this.cancelled) {
            return
        }

        this.blobs.push(event.data)
        if (lastBlob) {
            const result = await verifyBlobs(this.blobs)
            if (result.type === 'error') {
                this.errorStop(t('videoUploaderInvalidBlobError'))
                return
            }
            this.emit('onRecordingDone', result.blob)
        }
    }

    errorStop(err: any) {
        log('errorStop', err)

        if (!this.cancelled) {
            if (err.name === 'NotAllowedError') {
                displayError(err, undefined, <RecordingNotAllowedByBrowserErrorMessage />)
            } else {
                displayError(err)
            }
            this.emit('onError', err)
        }
        this.stopRecording()
    }

    // Not named stop() because stop function exists on window
    stopRecording() {
        log('stop')

        try {
            if (this.mediaRecorder) {
                const { state } = this.mediaRecorder
                if (state === 'recording' || state === 'paused') {
                    this.mediaRecorder.stop()
                }
            }

            this.releaseTracks()
            this.setRecordingState('STOPPED')
        } catch (err) {
            if (!this.cancelled) {
                this.emit('onError', err)
            }
            this.releaseTracks()
        }
    }

    pause() {
        log('pause')
        const { mediaRecorder } = this

        if (!mediaRecorder) {
            log('### mediaRecorder not set, PAUSE action skiped')
            return
        }

        try {
            this.setRecordingState('PAUSED')
            mediaRecorder.pause()
        } catch (err) {
            console.log(err)
        }
    }

    resume() {
        log('resume')
        const { mediaRecorder } = this

        if (!mediaRecorder) {
            log('### mediaRecorder not set, RESUME action skiped')
            return
        }

        try {
            mediaRecorder.resume()
            this.setRecordingState('RECORDING')
        } catch (err) {
            console.log('### resume failed', err)
        }
    }

    cancel() {
        log('cancel')
        this.cancelled = true
        this.stopRecording()
    }

    releaseTracks() {
        const tracks = this.mediaStream?.getTracks() ?? []
        for (const track of tracks) {
            track.stop()
        }
    }
}

// Adapts AudioRecorder to the IVideoRecorder interface so that it can be used
// everywhere VideoRecorder can.
// Note: Clients may want to create a ref to this component, so it must be a class component
@observer
export class AudioRecorderComponent extends Component<IVideoRecorder> {
    private recorder: AudioRecorder

    // eslint-disable-next-line react/no-unused-class-component-methods
    @observable recordingState: AVTTRecordingState = 'NOT_INITIALIZED'

    constructor(props: IVideoRecorder) {
        super(props)

        const { recordingCountdown, setMediaStream, autoGainControl } = this.props

        this.stop = this.stop.bind(this)
        this.startRecording = this.startRecording.bind(this)
        this.pause = this.pause.bind(this)
        this.resume = this.resume.bind(this)
        this.cancel = this.cancel.bind(this)
        this.onRecordingDone = this.onRecordingDone.bind(this)
        this.onError = this.onError.bind(this)

        this.recorder = new AudioRecorder({
            recordingCountdown,
            onRecordingStateChange: this.setRecordingState.bind(this),
            setMediaStream,
            autoGainControl
        })
    }

    componentDidMount() {
        this.recorder.addListener('onRecordingDone', this.onRecordingDone)
        this.recorder.addListener('onError', this.onError)
        const _record = () => this.recorder._record()
        setTimeout(_record, 1000)
    }

    componentWillUnmount() {
        const { setRecordingState } = this.props

        this.recorder.removeListener('onRecordingDone', this.onRecordingDone)
        this.recorder.removeListener('onError', this.onError)
        this.recorder.releaseTracks()
        setRecordingState?.('NOT_INITIALIZED') // reset recording state
    }

    async onRecordingDone(recordedBlob: Blob) {
        const { appendSilenceToEnd, onRecordingDone } = this.props
        const blob = appendSilenceToEnd ? await appendSilenceToEndOfRecording(recordedBlob) : recordedBlob
        const result = await verifyBlobs([blob])
        if (result.type === 'error') {
            this.onError(t('videoUploaderInvalidBlobError'))
            return
        }

        onRecordingDone(result.blob)
    }

    onError(err: any) {
        const { onRecordingError } = this.props
        onRecordingError(err)
    }

    setRecordingState = (state: AVTTRecordingState) => {
        const { setRecordingState } = this.props

        log('setRecordingState', state)
        // eslint-disable-next-line react/no-unused-class-component-methods
        this.recordingState = state

        // Following line has ? because not all callers want to be informed
        setRecordingState?.(state)
    }

    startRecording() {
        this.recorder.startRecording()
    }

    stop() {
        this.recorder.stopRecording()
    }

    pause() {
        this.recorder.pause()
    }

    resume() {
        this.recorder.resume()
    }

    cancel() {
        this.recorder.cancel()
    }

    render() {
        return <div className="video-recorder" />
    }
}
