import { EventEmitter } from 'events'

import { t } from 'i18next'
import { computed, observable } from 'mobx'
import _ from 'underscore'

import { ApiDotBible } from './ApiDotBible'
import { BiblicalTermMarker } from './BiblicalTermMarker'
import { DBAcceptor } from './DBAcceptor'
import { DBObject } from './DBObject'
import { DocumentCollection } from './DocumentCollection'
import { createDocumentObject, addDocumentObject } from './DocumentUtils'
import { IDB } from './IDB'
import { findMember, findMemberIndex, Member, MemberRole } from './Member'
import { PassageCopyVisitor } from './PassageCopyVisitor'
import { PassageDocument } from './PassageDocument'
import { Portion } from './Portion'
import { ProjectProfiles, ProjectRecordingInfo } from './ProjectEntity'
import { ProjectImage } from './ProjectImage'
import { ProjectMessage } from './ProjectMessage'
import { ProjectPlan } from './ProjectPlan'
import { ProjectTerm } from './ProjectTerm'
import { ReviewProject } from './ReviewProject'
import { SignVideo } from './SignVideo'
import { TargetGloss } from './TargetGloss'
import { TrashCan } from './TrashCan'
import { move, remove, normalizeUsername, restore } from './Utils'
import { canAccessInternet } from '../components/app/OnlineStatusContext'
import { DB_ACCEPTOR_VERSION, Limits } from '../components/app/slttAvtt'
import { getBookTexts } from '../components/passages/utils'
import { SegmentEditorPanel, SegmentEditorPanelLayout } from '../components/segments/SegmentPanelOrder'
import { PlanStructure } from '../components/status/plan/defaultPlans'
import { StudyTabKey, StudyTabLayout } from '../components/translation/StudyTabsOrder'
import { fmt } from '../components/utils/Fmt'
import { nnn } from '../components/utils/Helpers'
import { bookNamesByLanguage, getBookNames } from '../resources/bookNames'
import { LexMeaning, MarbleLemma } from '../resources/Lemmas'
import ProjectTermCollection from '../resources/ProjectTerms'
import { RefRange } from '../resources/RefRange'
import { convertReferenceStrings } from '../resources/Versifications'
import { AVTTError, DateFormat, DbObjectIdPrefix, ExportTextFormat, ExportTextType } from '../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

log(ApiDotBible.versions)

/*
Project
    Member
    Portion
        Passage
            PassageVideo
                PassageSegment
                    PassageSegmentLabel
                    PassageSegmentGloss
                PassageNote
                    PassageNoteItem
                PassageGloss
                
*/

export type GroupProjectConfiguration = {
    copyAdminsOnly: boolean
    copyPlan: boolean
    copyPortions: boolean
    includeResources: boolean
    latestDraftOnly: boolean
}

export interface ICopyFileToVideoCache {
    // Copies the file to VideoCache and returns the _id of the resulting video blob
    (file: Blob, baseUrl: string, requestUpload?: boolean): Promise<string>
}

type BookNamesRecord = { [bbb: string]: string }

export interface IVideoCacheAcceptor {
    // Notify video cache of new S3 file. See VideoCache.accept
    (_id: string): Promise<void>
}

interface SetPortionFromExisting {
    sourceProject: Project
    portionToCopy: Portion
    destinationPortion?: Portion
    includeResources: boolean
    latestDraftOnly: boolean
}

const defaultNoteColors = [
    '#418D2B',
    '#4873A6',
    '#1F2E69',
    '#418D2B',
    '#4873A6',
    '#1F2E69',
    '#418D2B',
    '#4873A6',
    '#1F2E69'
]

export class Project extends DBObject {
    name: string

    @observable projectProfiles?: ProjectProfiles

    projectRecordingInfo?: ProjectRecordingInfo

    @observable displayName = ''

    @observable text = ''

    initialized = false

    dbAcceptor?: DBAcceptor

    watcher: EventEmitter | null = null

    @observable bookNames: BookNamesRecord = {}

    @observable portions: Portion[]

    @observable members: Member[]

    @observable documents: PassageDocument[] = []

    @observable plans: ProjectPlan[] = [] // Currently there is only 1 plan

    @observable signs: { [index: string]: SignVideo }

    @observable terms: ProjectTerm[] = []

    @observable termCollection = new ProjectTermCollection()

    @observable copyrightStatement = ''

    @observable noteColors: string[] = defaultNoteColors

    @observable noteLabels: string[] = ['', '', '', '', '', '', '', '', '']

    @observable segmentStatusLabels: string[] = ['', '', '']

    @observable dateFormat: DateFormat = DateFormat.yyyymmdd

    @observable recordingCountdown = 3

    @observable autoGainControl = true

    @observable compressedVideoQuality = 20

    @observable compressedVideoResolution = 720

    @observable maxVideoSizeMB = 100

    @observable isEngageEnabled = false

    @observable overrideRecordingLayout = false

    @observable segmentEditorPanelLayout = SegmentEditorPanelLayout

    @observable segmentEditorHiddenPanels: SegmentEditorPanel[] = []

    @observable studyTabLayout = StudyTabLayout

    @observable studyTabHiddenTabs: StudyTabKey[] = []

    @observable images: ProjectImage[] = []

    @observable versification = 'English' // do not persist, since this is hardcoded

    @observable messages: ProjectMessage[] = []

    @observable projectType: 'translation' | 'additional' | 'test_training' | 'resource' | 'other' = 'translation'

    @observable language = ''

    @observable reviewProjects: ReviewProject[] = []

    oldPlanDoc: any = undefined

    documentCollection = new DocumentCollection()

    // not persisted
    @observable recordingsWithReviews = new Set<string>()

    static copyFileToVideoCache: ICopyFileToVideoCache

    static videoCacheAcceptor: IVideoCacheAcceptor

    @computed get allNoteColors() {
        // Handle case where the number of note colors saved by the user is different than
        // what we expect. This could happen if the colors were changed in an older version
        // of AVTT.
        return [...this.noteColors, ...defaultNoteColors.slice(this.noteColors.length)]
    }

    @computed get reviewProject() {
        if (this.reviewProjects.length) {
            return this.reviewProjects[0]
        }
    }

    constructor(
        name: string,
        db: IDB,
        displayName?: string,
        projectProfiles?: ProjectProfiles,
        projectRecordingInfo?: ProjectRecordingInfo
    ) {
        super('project', db)

        this.name = name

        if (projectProfiles) {
            this.projectProfiles = projectProfiles
        }

        if (projectRecordingInfo) {
            this.projectRecordingInfo = projectRecordingInfo
        }

        this.displayName = displayName || name
        this.db = db
        this.portions = []
        this.members = []
        this.signs = {}

        this.removePortion = this.removePortion.bind(this)
        this.restorePortion = this.restorePortion.bind(this)
        this.setDisplayName = this.setDisplayName.bind(this)
        this.setText = this.setText.bind(this)
        this.setDateFormat = this.setDateFormat.bind(this)
        this.setRecordingCountdown = this.setRecordingCountdown.bind(this)
        this.setAutoGainControl = this.setAutoGainControl.bind(this)
        this.canAddMember = this.canAddMember.bind(this)
        this.addImage = this.addImage.bind(this)
        this.moveImage = this.moveImage.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
        this.setCompressedVideoQuality = this.setCompressedVideoQuality.bind(this)
        this.setCompressedVideoResolution = this.setCompressedVideoResolution.bind(this)
        this.setMaxVideoSizeMB = this.setMaxVideoSizeMB.bind(this)
        this.setBookNames = this.setBookNames.bind(this)
        this.prepopulateBookNames = this.prepopulateBookNames.bind(this)
        this.setUpProjectReview = this.setUpProjectReview.bind(this)
        this.setLanguage = this.setLanguage.bind(this)
        for (let i = 0; i < 66; ++i) {
            const bbb = nnn(i + 1)
            this.bookNames[bbb] = bookNamesByLanguage.en[i]
        }
    }

    toDocument() {
        const { members } = this
        return this._toDocument({ members })
    }

    toSnapshot() {
        const snapshot: any = {}

        snapshot.members = this.members.map((member) => member.toSnapshot())
        snapshot.plan = this.plans.map((plan) => plan.toSnapshot())
        snapshot.portions = this.portions.map((portion) => portion.toSnapshot())
        snapshot.booksNames = this.bookNames

        return snapshot
    }

    async initialize(progress: (message: string) => void, trashCan: TrashCan, allowInvalidIds = false) {
        log('initialize')
        if (this.initialized) return 0

        log('startSync')
        this.dbAcceptor = new DBAcceptor(
            this,
            Project.videoCacheAcceptor,
            DB_ACCEPTOR_VERSION,
            trashCan,
            allowInvalidIds
        )

        const dbRecordCount = await this.db.initialize(this.dbAcceptor, progress)
        this.dbAcceptor.cleanUp()
        this.documentCollection.initialize(this)

        this.initialized = true

        return dbRecordCount
    }

    get passages() {
        return this.portions.flatMap((portion) => portion.passages)
    }

    private getText({
        exportTextType,
        exportTextFormat
    }: {
        exportTextType: ExportTextType
        exportTextFormat?: ExportTextFormat
    }) {
        const passages = this.portions.map((portion) => portion.passages).flat()
        return getBookTexts({ passages, project: this, exportTextType, exportTextFormat })
    }

    getBackTranslation(exportTextFormat?: ExportTextFormat) {
        return this.getText({ exportTextType: ExportTextType.BACK_TRANSLATION, exportTextFormat })
    }

    getTranscription(exportTextFormat?: ExportTextFormat) {
        return this.getText({ exportTextType: ExportTextType.TRANSCRIPTION, exportTextFormat })
    }

    cleanUpTargetGlosses() {
        const deletedGlosses = this.termCollection.removeUnusedTargetGlosses()
        deletedGlosses.forEach((glossId) => {
            this.terms.find((term) => term.glosses.find((gloss) => gloss._id === glossId))?.removeGloss(glossId)
        })
    }

    getPassageDocumentsByRef = (refs: RefRange[]) => {
        return this.documentCollection.get(refs)
    }

    createPortionFromExisting(portion: Portion) {
        let { name } = portion
        let number = 0
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        while (this.portions.find((p) => p.name === name)) {
            number++
            name = `${portion.name}-${number}`
        }
        const newPortion = this.createPortion(name)
        const copy = portion.copy()
        copy._id = newPortion._id
        copy.name = newPortion.name
        copy.rank = newPortion.rank
        copy.passages = []
        return copy
    }

    createPortion(name: string) {
        name = name.trim()
        if (this.portions.find((p) => p.name === name)) {
            throw Error(`${t('System Error')}: Duplicate name`)
        }

        const newId = this.db.getNewId(this.portions, new Date(Date.now()))
        const portion = new Portion(newId, this.db)

        portion.name = name

        let rank = 100
        if (this.portions.length > 0) {
            rank = this.portions.slice(-1)[0].rankAsNumber + 100
        }
        portion.rank = DBObject.numberToRank(rank)

        return portion
    }

    async saveNewPortion(portion: Portion) {
        if (this.portions.length + 1 > Limits.MAX_PORTIONS_PER_PROJECT) {
            throw new Error(AVTTError.LIMITS_MAX_PORTIONS_PER_PROJECT)
        }
        await this.db.put(portion.toDocument())
    }

    async addPortion(name: string) {
        const portion = this.createPortion(name)
        await this.saveNewPortion(portion)
        return this.findPortion(portion._id)
    }

    async addPortionFromExisting(portion: Portion) {
        await this.saveNewPortion(portion)
        const _portion = this.findPortion(portion._id)
        if (!_portion) throw new Error('could not find newly added Portion')
        return _portion
    }

    async removePortion(_id: string) {
        await remove(this.portions, _id)
    }

    async restorePortion(trashCanPortions: Portion[], _id: string) {
        await restore(trashCanPortions, _id)
    }

    async movePortion(_id: string, i: number) {
        const idx = _.findIndex(this.portions, { _id })
        if (idx === -1) throw Error(`movePortion: _id not found [${_id}]`)
        await move(this.portions, idx, i)
    }

    async setBookNames(names: BookNamesRecord) {
        const changes = Object.entries(names).filter(([key, value]) => this.bookNames[key] !== value)
        if (changes.length === 0) {
            return
        }
        const doc = this.toDocument()
        doc.projectBookNames = { ...this.bookNames, ...names }
        await this.db.put(doc)
    }

    async prepopulateBookNames(language: string) {
        const { bookNames } = this
        const names = getBookNames(language)
        const existingBookEntries = Object.keys(bookNames)
        const newBookNames: Record<string, string> = {}
        existingBookEntries.forEach((entry, i) => {
            newBookNames[entry] = names[i]
        })
        await this.setBookNames(newBookNames)
    }

    async setUpProjectReview() {
        if (this.isEngageEnabled && !this.reviewProject) {
            const project = new ReviewProject('rPrj', this.db)
            project.projectKey = this.name
            project.title = this.displayName
            const rank = 100
            project.rank = DBObject.numberToRank(rank)
            await this.db.put(project.toDocument())
        }
    }

    async addDefaultProjectPlan(planStructure: PlanStructure) {
        const plan = this.createProjectPlan(new Date(Date.now()))
        await this.db.put(plan.toDocument())
        if (!this.plans.length) {
            log('Project plan was not successfully added')
            return
        }

        const newPlan = this.plans[this.plans.length - 1]
        await newPlan.addDefaultPlan(planStructure)
    }

    async addProjectPlan() {
        if (this.plans.length > 0) {
            throw Error('Plan already exists')
        }

        const plan = this.createProjectPlan(new Date(Date.now()))
        const doc = plan.toDocument()
        await this.db.put(doc)

        return this.plans[0]
    }

    async setViewableStagesFromExisting({
        sourceProject,
        ignoreIfNoPlan = false
    }: {
        sourceProject: Project
        ignoreIfNoPlan?: boolean
    }) {
        if (!sourceProject.plans.length) {
            if (ignoreIfNoPlan) {
                return
            }
            throw Error(
                t('No plan was found to copy from project {{project}}', {
                    project: sourceProject.getFormattedDisplayName()
                })
            )
        }

        const sourceProjectPlan = sourceProject.plans[0]

        const wasNewPlanCreated = this.plans.length === 0
        const destinationPlan = this.plans.length ? this.plans[0] : await this.addProjectPlan()
        if (!destinationPlan) {
            throw Error(
                t('No plan was found to copy into project {{projectName}}', {
                    projectName: this.getFormattedDisplayName()
                })
            )
        }

        // No need to delete non-viewable stages
        await this.removeViewableStages()

        // If this project did not have a plan, we need to copy the non-viewable stages.
        // Else, don't copy them, since we didn't delete them.
        const stagesToAdd = wasNewPlanCreated ? sourceProjectPlan.stages : sourceProjectPlan.viewableStages
        for (const stage of stagesToAdd) {
            const destinationStage = await destinationPlan.addStage(stage.index, stage.name)
            for (const task of stage.tasks) {
                await destinationStage.addTask(destinationPlan, task.taskPosition - 1, task.name, task.details)
            }
        }
    }

    createProjectPlan(creationDate: Date) {
        const newId = this.db.getNewId(this.plans, creationDate, 'plan_')
        const plan = new ProjectPlan(newId, this.db)

        const rank = 100
        plan.rank = DBObject.numberToRank(rank)

        return plan
    }

    async removeViewableStages() {
        if (!this.plans.length) {
            throw Error(
                t('No plan was found for project {{projectName}}', { projectName: this.getFormattedDisplayName() })
            )
        }

        const plan = this.plans[0]
        for (const stage of plan.viewableStages) {
            await plan.removeStage(this, stage._id)
        }
    }

    canAddMember(email: string) {
        email = normalizeUsername(email)
        if (email === '') return t('emailEmptyMessage')

        if (findMember(this.members, email)) return t('emailDuplicateMessage')
        if (email.split('@').length !== 2) return t('emailInvalidMessage')

        return ''
    }

    async addMember(email: string, role: MemberRole = 'translator') {
        if (!(await canAccessInternet('addMember'))) {
            throw Error(t('offlineActionError'))
        }

        email = normalizeUsername(email)
        if (findMember(this.members, email)) {
            return
        }

        const doc = this.toDocument()
        const _doc: any = doc
        _doc.members.push({ email, role })

        await this.db.put(_doc)

        // FUTURE: Update only the member that has changed, backend must update project table
        // let doc = this._toDocument({ model: 2, email, role: 'translator' })
        // doc._added = true
        // doc._id = 'member'
        // await this.db.put(doc)
    }

    async removeMember(email: string) {
        const doc = this.toDocument()
        const _doc: any = doc

        const index = findMemberIndex(_doc.members, email)
        if (index < 0) return

        _doc.members.splice(index, 1)

        await this.db.put(_doc)

        // FUTURE: Update only the member that has changed, backend must update project table
        // let doc = this._toDocument({ model: 2, email })
        // doc._removed = true
        // doc._id = 'member'
        // await this.db.put(doc)
    }

    async setMemberRole(email: string, role: MemberRole) {
        log('setmemberRole', email, role)

        const doc = this.toDocument()
        const _doc: any = doc

        const member = findMember(_doc.members, email)
        if (!member) return

        member.role = role

        await this.db.put(_doc)

        // FUTURE: Update only the member that has changed, backend must update project table
        // let member = findMember(this.members, email)
        // if (!member || member.role === role) {
        //     return
        // }
        // let doc = this._toDocument({ model: 2, email, role })
        // doc._id = 'member'
        // await this.db.put(doc)
    }

    getDefaultPortion(_id: string | null) {
        const { portions } = this
        let portion: Portion | undefined
        if (_id) {
            portion = this.findPortion(_id)
        }
        if (!portion && portions.length > 0) {
            portion = portions[0]
        }

        return portion
    }

    async setPortionFromExisting({
        sourceProject,
        portionToCopy,
        destinationPortion,
        includeResources,
        latestDraftOnly
    }: SetPortionFromExisting) {
        let newPortion: Portion | undefined
        try {
            const getPortion = async (): Promise<Portion> => {
                if (destinationPortion) {
                    return destinationPortion
                }
                const portion = this.createPortionFromExisting(portionToCopy)
                portion.copiedFromId = `${sourceProject.name}/${portionToCopy._id}`
                return this.addPortionFromExisting(portion)
            }

            newPortion = await getPortion()
            for (const passage of portionToCopy.passages) {
                const visitor = new PassageCopyVisitor({
                    newProject: this,
                    oldProject: sourceProject,
                    newPortion,
                    oldPassage: passage,
                    options: {
                        includeResources,
                        latestDraftOnly,
                        includeVideos: true
                    }
                })
                await visitor.visit()
            }
        } catch (error) {
            if (!destinationPortion && newPortion) {
                await this.removePortion(newPortion._id)
            }

            if (error instanceof Error && error.message in AVTTError) {
                throw error
            }

            throw new Error(AVTTError.COULD_NOT_COPY)
        }
    }

    async setPortionsFromExisting({
        sourceProject,
        portions,
        includeResources,
        latestDraftOnly
    }: Omit<SetPortionFromExisting, 'portionToCopy'> & { portions?: Portion[] }) {
        // Needs to be synchronous so that error checks can be done without having to worry about
        // race conditions
        const portionsToCopy = portions || sourceProject.portions
        for (const portionToCopy of portionsToCopy) {
            await this.setPortionFromExisting({ sourceProject, portionToCopy, includeResources, latestDraftOnly })
        }
    }

    async sortPortions() {
        const portions = _.sortBy(this.portions, (portion) => portion.name)
        for (let i = 0; i < portions.length; ++i) {
            await portions[i].setRank(100 * (i + 1))
        }
    }

    async logPortions() {
        const { portions } = this
        for (let i = 0; i < portions.length; ++i) {
            const p = portions[i]
            console.log(`${p.name} = ${p.rank}`)
        }
    }

    createProjectTermFromExisting(term: ProjectTerm) {
        const newTerm = this.createProjectTerm(term.lexicalLink)
        const copy = term.copy()
        copy._id = newTerm._id
        copy.glosses = []
        copy.renderings = []
        return copy
    }

    createProjectTerm(lexicalLink: string) {
        const newId = this.db.getNewId(this.terms, new Date(Date.now()), 'term_')
        const term = new ProjectTerm(newId, this.db)
        term.lexicalLink = lexicalLink

        let rank = 100
        if (this.terms.length > 0) {
            rank = this.terms[0].rankAsNumber / 2
        }
        term.rank = DBObject.numberToRank(rank)
        return term
    }

    async addProjectTerm(term: ProjectTerm) {
        await this.db.put(term.toDocument())
        return this.getProjectTerm(term.lexicalLink)
    }

    acceptProjectTerm(term: ProjectTerm) {
        this.termCollection.addProjectTerm(term)
    }

    acceptTargetGloss(gloss: TargetGloss) {
        this.termCollection.addTargetGloss(gloss)
    }

    acceptBiblicalTermMarker(marker: BiblicalTermMarker) {
        this.termCollection.addBiblicalTermMarker(marker)
    }

    acceptDeletedTermMarker(marker: BiblicalTermMarker) {
        this.termCollection.removeBiblicalTermMarker(marker)
    }

    getProjectTerm(lexicalLink: string) {
        return this.termCollection.getProjectTermByLexicalLink(lexicalLink)
    }

    async migrateLemma(lemma: MarbleLemma) {
        const signVideo = this.signs[`sign/${lemma?.id}`]
        if (!signVideo) {
            return
        }

        const promises = lemma?.meanings.map((meaning) => this.migrateMeaning(meaning, signVideo)) ?? []
        await Promise.all(promises)
    }

    private async migrateMeaning(meaning: LexMeaning, signVideo: SignVideo) {
        let term = this.getProjectTerm(meaning.lexicalLink)
        if (!term) {
            term = this.createProjectTerm(meaning.lexicalLink)
            await this.addProjectTerm(term)
            const _term = this.getProjectTerm(term.lexicalLink)
            if (!_term) {
                return
            }
            term = _term
        }

        // Only migrate rendering if it hasn't been done before
        if (term.renderings.find((r) => r.url === signVideo.url)) {
            return
        }

        const rendering = term.createRendering()
        rendering.url = signVideo.url
        await term.addRendering(rendering)

        // Do not delete the old renderings. If someone is still using an
        // old version of xxTT, it will look like their data was lost.
    }

    getKeyTermsThatOccurInVerses(refRanges: RefRange[]) {
        const uniqueVerses = RefRange.getAllVersesInRefRanges(refRanges, this.versification)
        const originalVerses = convertReferenceStrings(uniqueVerses, this.versification, 'Original')
        const terms = new Set<ProjectTerm>()
        for (const verse of originalVerses) {
            const newTerms = this.termCollection.getProjectTermsByVerse(verse)
            for (const term of newTerms) {
                if (term.isKeyTerm) {
                    terms.add(term)
                }
            }
        }

        const termsArray = Array.from(terms)
        return termsArray
    }

    getFormattedDisplayName = () => (this.name === this.displayName ? this.name : `${this.displayName} (${this.name})`)

    createProjectMessage() {
        const creationDate = this.db.getNewId(this.messages, new Date(Date.now()))
        const _id = `notify/${creationDate}`
        return new ProjectMessage(_id, this.db)
    }

    async addProjectMessage(notif: ProjectMessage) {
        const { name } = this
        const { subject, globalMessage } = notif
        log('addProjectMessage', fmt({ project: name, subject, globalMessage }))

        await this.db.put(notif.toDocument())
    }

    async removeProjectMessage(notif: ProjectMessage) {
        const doc = notif._toDocument({ parent: notif.parent })
        doc.removed = true
        await notif.db.put(doc)
    }

    async setCopyrightStatement(copyrightStatement: string) {
        const trimmedCopyright = copyrightStatement.trim()
        if (this.copyrightStatement === trimmedCopyright) {
            return
        }

        const doc = this.toDocument()
        doc.copyrightStatement = trimmedCopyright
        await this.db.put(doc)
    }

    // _id can be for a portion or any of its subobjects (e.g. Passage)
    findPortion(_id: string) {
        return this.portions.find((p) => _id.startsWith(p._id))
    }

    // _id can be for a passage or any of its subobjects (e.g. PassageVideo)
    findPassage(_id: string) {
        const portion = this.findPortion(_id)
        return portion?.passages.find((p) => _id.startsWith(p._id))
    }

    async setNoteColors(noteColors: string[]) {
        if (JSON.stringify(noteColors) === JSON.stringify(this.noteColors)) {
            return
        }
        const doc = this._toDocument({ model: 1, noteColors })
        doc._id = 'teamPreferences'
        await this.db.put(doc)
    }

    async setNoteLabels(noteLabels: string[]) {
        if (JSON.stringify(noteLabels) === JSON.stringify(this.noteLabels)) {
            return
        }
        const doc = this._toDocument({ model: 23, noteLabels })
        doc._id = 'teamPreferences'
        await this.db.put(doc)
    }

    async setSegmentStatusLabels(segmentStatusLabels: string[]) {
        if (JSON.stringify(segmentStatusLabels) === JSON.stringify(this.segmentStatusLabels)) {
            return
        }
        const doc = this._toDocument({ model: 23, segmentStatusLabels })
        doc._id = 'teamPreferences'
        await this.db.put(doc)
    }

    async setDateFormat(dateFormat: DateFormat) {
        if (dateFormat === this.dateFormat) {
            return
        }
        const doc = this._toDocument({ model: 1, dateFormat })
        doc._id = 'teamPreferences'
        await this.db.put(doc)
    }

    async setRecordingCountdown(recordingCountdown: number) {
        if (recordingCountdown === this.recordingCountdown) {
            return
        }

        const doc = this._toDocument({ model: 24, recordingCountdown })
        doc._id = 'teamPreferences'
        await this.db.put(doc)
    }

    async setAutoGainControl(autoGainControl: boolean) {
        if (autoGainControl === this.autoGainControl) {
            return
        }

        const doc = this._toDocument({ model: 24, autoGainControl })
        doc._id = 'teamPreferences'
        await this.db.put(doc)
    }

    async setCompressedVideoQuality(compressedVideoCRF: number) {
        if (compressedVideoCRF === this.compressedVideoQuality) {
            return
        }
        const doc = this.toDocument()
        doc.model = 9
        doc.compressedVideoCRF = compressedVideoCRF
        await this.db.put(doc)
    }

    async setCompressedVideoResolution(compressedVideoResolution: number) {
        if (compressedVideoResolution === this.compressedVideoResolution) {
            return
        }
        const doc = this.toDocument()
        doc.model = 9
        doc.compressedVideoResolution = compressedVideoResolution
        await this.db.put(doc)
    }

    async setMaxVideoSizeMB(maxVideoSizeMB: number) {
        if (maxVideoSizeMB === this.maxVideoSizeMB) {
            return
        }
        const doc = this.toDocument()
        doc.model = 9
        doc.maxVideoSizeMB = maxVideoSizeMB
        await this.db.put(doc)
    }

    async setIsEngageEnabled(isEngageEnabled: boolean) {
        if (isEngageEnabled === this.isEngageEnabled) {
            return
        }

        if (!isEngageEnabled && this.reviewProject) {
            await this.reviewProject.setIsActive(isEngageEnabled)
        }

        const doc = this.toDocument()
        doc.model = 25
        doc.isEngageEnabled = isEngageEnabled
        await this.db.put(doc)
    }

    async setDisplayName(displayName: string) {
        const trimmedDisplayName = displayName.trim()
        if (trimmedDisplayName === this.displayName || trimmedDisplayName === '') {
            return
        }

        const doc = this._toDocument({ model: 3, displayName: trimmedDisplayName })
        doc._id = 'projectPreferences'
        await this.db.put(doc)
    }

    async setText(text: string) {
        if (text === this.text) {
            return
        }

        const doc = this._toDocument({ model: 3, text })
        doc._id = 'projectPreferences'
        await this.db.put(doc)
    }

    async setProjectType(projectType: string) {
        if (projectType === this.projectType) {
            return
        }

        const doc = this._toDocument({ model: 3, projectType })
        doc._id = 'projectPreferences'
        await this.db.put(doc)
    }

    async setLanguage(language: string) {
        if (language === this.language) {
            return
        }

        const doc = this._toDocument({
            _id: 'projectPreferences',
            language,
            model: 29
        })
        await this.db.put(doc)
    }

    async setOverrideRecordingLayout(overrideRecordingLayout: boolean) {
        if (overrideRecordingLayout === this.overrideRecordingLayout) {
            return
        }

        const doc = this._toDocument({
            _id: 'projectPreferences',
            model: 20,
            overrideRecordingLayout
        })
        await this.db.put(doc)
    }

    async setSegmentEditorPanelLayout(segmentEditorPanelLayout: SegmentEditorPanel[]) {
        if (JSON.stringify(segmentEditorPanelLayout) === JSON.stringify(this.segmentEditorPanelLayout)) {
            return
        }

        const doc = this._toDocument({
            _id: 'projectPreferences',
            model: 20,
            segmentEditorPanelLayout
        })
        await this.db.put(doc)
    }

    async setSegmentEditorPanelVisibility(segmentEditorPanel: SegmentEditorPanel, visible: boolean) {
        const hiddenPanels = [...this.segmentEditorHiddenPanels]

        if (visible) {
            const index = hiddenPanels.indexOf(segmentEditorPanel)
            if (index === -1) {
                return
            }
            hiddenPanels.splice(index, 1)
        } else {
            if (hiddenPanels.includes(segmentEditorPanel)) {
                return
            }
            hiddenPanels.push(segmentEditorPanel)
        }

        const doc = this._toDocument({
            _id: 'projectPreferences',
            model: 20,
            segmentEditorHiddenPanels: hiddenPanels
        })
        await this.db.put(doc)
    }

    async setStudyTabLayout(studyTabLayout: StudyTabKey[]) {
        if (JSON.stringify(studyTabLayout) === JSON.stringify(this.studyTabLayout)) {
            return
        }

        const doc = this._toDocument({
            _id: 'projectPreferences',
            model: 28,
            studyTabLayout
        })
        await this.db.put(doc)
    }

    async setStudyTabHiddenTabs(studyTabKey: StudyTabKey, visible: boolean) {
        const hiddenPanels = [...this.studyTabHiddenTabs]

        if (visible) {
            const index = hiddenPanels.indexOf(studyTabKey)
            if (index === -1) {
                return
            }
            hiddenPanels.splice(index, 1)
        } else {
            if (hiddenPanels.includes(studyTabKey)) {
                return
            }
            hiddenPanels.push(studyTabKey)
        }

        const doc = this._toDocument({
            _id: 'projectPreferences',
            model: 28,
            studyTabHiddenTabs: hiddenPanels
        })
        await this.db.put(doc)
    }

    createDocument(title: string) {
        const { db, documents } = this
        const id = db.getNewId(documents, new Date(Date.now()), DbObjectIdPrefix.PROJECT_DOCUMENT)
        return createDocumentObject({
            id,
            parent: this,
            title
        })
    }

    createDocumentFromExisting(passageDocument: PassageDocument) {
        const newDocument = this.createDocument('')
        const copy = passageDocument.copy()
        copy._id = newDocument._id
        return copy
    }

    async addDocument(passageDocument: PassageDocument) {
        return addDocumentObject({ parent: this, passageDocument })
    }

    async removeDocument(_id: string) {
        await remove(this.documents, _id)
    }

    async addImage(src: string) {
        src = src.trim()
        if (src === '' || this.images.find((image) => image.src === src)) {
            return
        }
        const newId = this.db.getNewId(this.images, new Date(Date.now()), 'prjImg_')
        const image = new ProjectImage(newId, this.db)
        image.src = src

        let rank = 100
        if (this.images.length > 0) {
            rank = this.images[0].rankAsNumber / 2
        }
        image.rank = DBObject.numberToRank(rank)
        await this.db.put(image.toDocument())
    }

    async deleteImage(_id: string) {
        await remove(this.images, _id)
    }

    async moveImage(_id: string, i: number) {
        const idx = _.findIndex(this.images, { _id })
        if (idx === i) {
            return
        }

        if (idx === -1) throw Error(`moveProjectIcon: _id not found [${_id}]`)
        await move(this.images, idx, i)
    }

    async copyDataToGroupProject(config: GroupProjectConfiguration, group: Project) {
        const { copyAdminsOnly, copyPlan, copyPortions, includeResources, latestDraftOnly } = config

        const membersToAdd = group.members.filter((member) => (copyAdminsOnly ? member.role === 'admin' : true))

        // We need to do this synchronously because these calls edit the same database object
        for (const member of membersToAdd) {
            await this.addMember(member.email, member.role)
        }

        const promises: Promise<void>[] = []
        if (copyPlan) {
            promises.push(
                this.setViewableStagesFromExisting({
                    sourceProject: group,
                    ignoreIfNoPlan: true
                })
            )
        }
        if (copyPortions) {
            promises.push(
                this.setPortionsFromExisting({
                    sourceProject: group,
                    includeResources,
                    latestDraftOnly
                })
            )
        }
        await Promise.all(promises)
    }

    *passageVideos() {
        for (const portion of this.portions) {
            for (const passage of portion.passages) {
                for (const video of passage.videos) {
                    if (video.removed) continue
                    yield video
                }
            }
        }
    }

    countAll() {
        let [portions, passages, videos, noteItems, glosses] = [0, 0, 0, 0, 0]

        for (const portion of this.portions) {
            ++portions
            for (const passage of portion.passages) {
                ++passages
                for (const video of passage.videos) {
                    if (video.removed) continue
                    ++videos
                    for (const note of video.notes) {
                        noteItems += note.items.length
                    }
                    glosses += video.glosses.length
                }
            }
        }

        return { name: this.name, portions, passages, videos, noteItems, glosses }
    }

    videod() {
        return this.portions.some((portion) => portion.passages.some((passage) => passage.videod()))
    }
}
