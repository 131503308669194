import { PropsWithChildren } from 'react'

import { useTranslation } from 'react-i18next'

import { CancelButton, OKButton, PencilButton } from '../utils/Buttons'

import './Segments.css'

interface SegmentEditorPanelProps {
    isEditing: boolean
    className: string
}

export const SegmentEditorPanel = ({ children, isEditing, className }: PropsWithChildren<SegmentEditorPanelProps>) => {
    return <div className={`segment-transcription ${className} ${isEditing ? 'editing' : ''}`}>{children}</div>
}

export const FormControls = ({
    showOKButton = true,
    enableSaveButton,
    onSave,
    onCancel
}: {
    showOKButton?: boolean
    enableSaveButton: boolean
    onSave: () => Promise<void>
    onCancel: () => void
}) => {
    const { t } = useTranslation()

    return (
        <div className="segment-document-bottom button-row">
            {showOKButton && (
                <OKButton
                    enabled={enableSaveButton}
                    onClick={onSave}
                    buttonClassName=""
                    className="large-right-pane-button segment-document-ok-button"
                    tooltip={t('Save edits and close')}
                />
            )}
            <CancelButton enabled className="large-right-pane-button" tooltip={t('Cancel edits')} onClick={onCancel} />
        </div>
    )
}

export const EditorHeader = ({
    headerText,
    onClick,
    isEditEnabled,
    rightContent
}: {
    headerText: string
    onClick: () => void
    isEditEnabled: boolean
    rightContent?: React.ReactNode
}) => {
    const { t } = useTranslation()
    return (
        <div className="segment-dialog-heading-group">
            <div className="segment-dialog-heading">{headerText}</div>
            <div className="button-row-space-between">
                <div className="button-row">
                    <PencilButton
                        enabled={isEditEnabled}
                        onClick={onClick}
                        className="right-pane-button default-blue-icon"
                        tooltip={t('Edit')}
                    />
                </div>
            </div>
            {rightContent}
        </div>
    )
}
