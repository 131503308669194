import { FC } from 'react'

import Select from '../select/Select'

export interface ILocalizedChoice {
    choice: string
    localizedChoice: string
    title: string
}

type IMakeLocalizedChoice = {
    choices: ILocalizedChoice[]
    choice: string
    setChoice: (_choice: string) => void
}

const formatOptionLabel = (data: { label: string; title: string }) => <div title={data.title}>{data.label}</div>

export const MakeLocalizedChoice: FC<IMakeLocalizedChoice> = ({ choices, choice, setChoice }) => {
    const options = choices.map((projectType) => ({
        value: projectType.choice,
        label: projectType.localizedChoice,
        title: projectType.title
    }))

    const selectedOption = options.find((option) => option.value === choice)

    return (
        <Select
            options={options}
            value={selectedOption}
            onChange={(selected) => {
                if (selected) {
                    setChoice(selected.value)
                }
            }}
            formatOptionLabel={formatOptionLabel}
            isClearable={false}
            isSearchable={false}
            styles={{
                container: (provided) => ({
                    ...provided,
                    width: '270px'
                })
            }}
        />
    )
}
