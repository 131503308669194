import _ from 'underscore'

import { DBObject } from './DBObject'

export const smallNoteMarker = 8
export const largeNoteMarker = 15

// Find the rank of adding an item before the index
export const getRankOfAddingBetween = ({
    startIndex,
    endIndex,
    items
}: {
    startIndex: number
    endIndex: number
    items: DBObject[]
}) => {
    const ranks = items.map((item) => parseFloat(item.rank))

    const startRank = startIndex in ranks ? ranks[startIndex] : 0
    const endRank = endIndex in ranks ? ranks[endIndex] : startRank + 200

    return DBObject.numberToRank((startRank + endRank) / 2)
}

export const limit = (value: number, low: number, high: number) => {
    value = Math.max(value, low)
    value = Math.min(value, high)
    return value
}

export const normalizeUsername = (username: string) => {
    username = username ?? '' // just in case an undefined somehow gets passed
    return username.trim().toLowerCase()
}

export const move = async (items: any[], oldIndex: number, newIndex: number) => {
    if (newIndex === oldIndex) return
    if (items.length <= 1) return

    let rank: number

    // Assign a rank to an item to cause it to order at the desired index
    if (newIndex === 0) {
        // Insert at beginning
        rank = items[0].rankAsNumber / 2
    } else if (newIndex === items.length - 1) {
        // Insert at end
        rank = items[items.length - 1].rankAsNumber + 100
    } else {
        let i
        if (newIndex > oldIndex) {
            i = newIndex
        } else {
            i = newIndex - 1
        }

        // Insert between two items
        const prevRank = items[i].rankAsNumber
        const nextRank = items[i + 1].rankAsNumber
        rank = (prevRank + nextRank) / 2
    }

    const item = items[oldIndex]
    await item.setRank(rank)
}

export const remove = async (items: any[], _id: string) => {
    const idx = _.findIndex(items, { _id })
    if (idx < 0) return

    const item = items[idx]

    const doc = item._toDocument({})
    doc.removed = true
    await item.db.put(doc)
}

export const restore = async (items: any[], _id: string) => {
    const idx = _.findIndex(items, { _id })
    if (idx < 0) return

    const item = items[idx]

    const doc = item._toDocument({})
    doc.removed = false
    await item.db.put(doc)
}

export const getDefault = (tag: string, projectName: string) => {
    // check if we are not running in a browser, this should only happen during unit tests
    if (typeof localStorage === 'undefined') return ''

    const key = `defaults.${projectName}.${tag}`
    return localStorage.getItem(key)
}

export const setDefault = (tag: string, value: string | null, projectName: string) => {
    // check if we are not running in a browser, this should only happen during unit tests
    if (typeof localStorage === 'undefined') return

    const key = `defaults.${projectName}.${tag}`
    value = value || ''
    localStorage.setItem(key, value)
}
