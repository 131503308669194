import { useState } from 'react'

import { observer } from 'mobx-react'

import { FetchBlobParams, useFetchBlob } from '../../hooks/useFetchBlob'
import { LoadingIcon } from '../utils/Icons'

import './AudioPlayer.css'

export const MediaFetchPlayer = observer(
    ({ fetchBlob, className = 'default-player' }: { fetchBlob: FetchBlobParams; className?: string }) => {
        const { blobUrl, mimeType, isError, isLoading } = useFetchBlob(fetchBlob)
        const [isImageExpanded, setIsImageExpanded] = useState(false)

        if (isError) {
            return null
        }

        if (isLoading) {
            return (
                <div className="audio-player-wrapper">
                    <LoadingIcon className={className} />
                </div>
            )
        }

        if (mimeType.includes('audio')) {
            return (
                <div className="audio-player-wrapper">
                    <audio src={blobUrl} className={className} controls />
                </div>
            )
        }

        if (mimeType.includes('video')) {
            return <video src={blobUrl} controls className={className} />
        }

        if (mimeType.includes('image')) {
            return (
                <img
                    src={blobUrl}
                    className={isImageExpanded ? 'default-player' : className}
                    onClick={() => setIsImageExpanded((prev) => !prev)}
                />
            )
        }

        return null
    }
)
