import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Project } from '../../models3/Project'
import { refToBookId, refRangesMinMax } from '../../resources/RefRange'
import { refToUsfmBookHeader } from '../../resources/Usfm'
import { ExportTextFormat, ExportTextType, ExportType } from '../../types'
import { groupBy } from '../utils/Helpers'

// Sort by starting reference, then by ending reference, then by name
// Warning: input passages array is mutated, so make a copy if you need to keep the original
export const getSortedPassages = (passages: Passage[]) =>
    passages.sort((a, b) => {
        // defaultReferences is a getter function, so save it to avoid repeated calls
        const aReferences = a.defaultReferences
        const bReferences = b.defaultReferences

        if (!aReferences.length && !bReferences.length) {
            return a.name.localeCompare(b.name)
        }

        if (!aReferences.length) {
            return 1 // "a" is after "b" since "a" has no references
        }

        if (!bReferences.length) {
            return -1 // "a" is before "b" since "b" has no references
        }

        const aRefSummary = refRangesMinMax(aReferences)
        const bRefSummary = refRangesMinMax(bReferences)

        return (
            aRefSummary.startRef.localeCompare(bRefSummary.startRef) ||
            aRefSummary.endRef.localeCompare(bRefSummary.endRef) ||
            a.name.localeCompare(b.name)
        )
    })

export const rerankPortionPassages = async (portion: Portion) => {
    const passagesCopy = getSortedPassages([...portion.passages]) // do not mutate the original array
    await Promise.all(passagesCopy.map((passage, index) => passage.setRank((index + 1) * 100)))
}

export const getSortedPassagesGroupedByBook = (passages: Passage[]) => {
    const withRecording = passages.filter((passage) => passage.videosNotDeleted.length)
    const sorted = getSortedPassages(withRecording).filter((passage) => passage.defaultReferences.length)
    return groupBy(sorted, (passage) => refToBookId(passage.defaultReferences[0].startRef))
}

const getSortedPortions = (portions: Portion[]) =>
    portions.sort((a, b) => {
        const aReferences = a.references
        const bReferences = b.references

        if (!aReferences.length && !bReferences.length) {
            return a.name.localeCompare(b.name)
        }

        if (!aReferences.length) {
            return 1
        }

        if (!bReferences.length) {
            return -1
        }

        const aRefSummary = refRangesMinMax(aReferences)
        const bRefSummary = refRangesMinMax(bReferences)

        return (
            aRefSummary.startRef.localeCompare(bRefSummary.startRef) ||
            aRefSummary.endRef.localeCompare(bRefSummary.endRef) ||
            a.name.localeCompare(b.name)
        )
    })

export const rerankProjectPortions = async (project: Project) => {
    const portionsCopy = getSortedPortions([...project.portions])
    await Promise.all(portionsCopy.map((portion, index) => portion.setRank((index + 1) * 100)))
}

export interface BookText {
    bookId: string
    text: string
}

export const getBookTexts = ({
    passages,
    project,
    exportTextType,
    exportTextFormat
}: {
    passages: Passage[]
    project: Project
    exportTextType: ExportTextType
    exportTextFormat?: ExportTextFormat
}): BookText[] => {
    const getText = (passage: Passage, previousChapterId: string) => {
        const params = {
            passage,
            project,
            exportTextFormat,
            includeHeader: false,
            previousChapterId
        }

        return exportTextType === ExportTextType.TRANSCRIPTION
            ? passage.latestVideo?.getTranscription(params)
            : passage.latestVideo?.getBackTranslation(params)
    }

    const bookPassages = getSortedPassagesGroupedByBook(passages)
    const bookTexts: BookText[] = []
    for (const bookId of Object.keys(bookPassages)) {
        const passagesForBook = bookPassages[bookId]
        const textsForBook: string[] = []
        let previousChapterId = ''

        for (const passage of passagesForBook) {
            const passageText = getText(passage, previousChapterId)
            if (passageText) {
                textsForBook.push(passageText.text)
            }

            previousChapterId = passageText?.lastChapterId ?? ''
        }

        if (textsForBook.length > 0) {
            const concatenatedText = textsForBook.join('\n')
            const header = exportTextFormat === ExportTextFormat.USFM ? refToUsfmBookHeader(bookId, project) : ''
            bookTexts.push({ bookId, text: `${header}${concatenatedText}` })
        }
    }

    return bookTexts
}

export const bookTextsToString = (bookTexts?: BookText[]) =>
    bookTexts?.map(({ text: bookText }) => bookText).join('\n') ?? ''

export const getFileExtension = (exportTextFormat: ExportTextFormat) =>
    exportTextFormat === ExportTextFormat.USFM ? '.usfm' : '.txt'

export const getExportTextType = (exportType: ExportType) => {
    if (exportType === ExportType.BACK_TRANSLATION) {
        return ExportTextType.BACK_TRANSLATION
    }

    if (exportType === ExportType.TRANSCRIPTION) {
        return ExportTextType.TRANSCRIPTION
    }

    return ''
}
