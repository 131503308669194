import { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useOnlineStatus } from '../components/app/OnlineStatusContext'
import { useAppRoot } from '../components/app/RootContext'
import { Option } from '../components/projectSettings/projectResources/MultiSelect'
import { OralTranscriber } from '../components/utils/oralTranscriber/OralTranscriber'

export const useOralTranscriptionLanguage = () => {
    const { isOnline } = useOnlineStatus()
    const { rt } = useAppRoot()
    const { t } = useTranslation()

    const defaultTranscriberLanguage = rt?.getDefault('oralBackTranslationLanguage')
    const [value, setValue] = useState<Option<string>[]>([])
    const supportedLanguages = OralTranscriber.supportedLanguages
    supportedLanguages[0].label = t('Do not automatically transcribe')

    useEffect(() => {
        const initialLanguage = isOnline
            ? supportedLanguages.find((option) => option.value === defaultTranscriberLanguage) || supportedLanguages[0]
            : supportedLanguages[0]

        setValue([initialLanguage])
    }, [isOnline, defaultTranscriberLanguage, supportedLanguages])

    const saveTranscriberLanguage = useCallback(
        (selected: Option<string>[]) => {
            if (!selected.length) {
                return // this can happen when user selects the current value again
            }

            rt?.setDefault('oralBackTranslationLanguage', selected[0].value)
            setValue(selected)
        },
        [rt]
    )

    const transcriberLanguage = isOnline ? value[0] : supportedLanguages[0]

    return { transcriberLanguage, saveTranscriberLanguage, supportedLanguages }
}
