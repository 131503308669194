/* eslint-disable import/no-cycle */
import { FC } from 'react'

import { Auth } from 'aws-amplify'
import classNames from 'classnames'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import { Navbar } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LocaleSelector } from './LocaleSelector'
import { useOnlineStatus } from './OnlineStatusContext'
import { ProjectSelector } from './ProjectSelector'
import { useAppRoot } from './RootContext'
import { avttHeader } from './slttAvtt'
import { Root } from '../../models3/Root'
import { SlttHelp } from '../utils/Buttons'
import { getEmailPrefix } from '../utils/Helpers'
import { HeadsetIcon, HelpLink } from '../utils/Icons'
import { OfflineMessage } from '../utils/OfflineMessage'

interface INavigationBar {
    selectPage: (page: string) => void
    selected: string
    selectProject: (projectName: string) => void
}

export const NavigationBar: FC<INavigationBar> = observer(({ selectPage, selected, selectProject }) => {
    const { i18n } = useTranslation()
    const appRoot = useAppRoot()
    const { isOnline } = useOnlineStatus()

    const { iAmRoot, iAmRootObserver, username, useMobileLayout, rt, auth_type } = appRoot

    const hasPortions = rt ? Boolean(rt.project.portions.length) : false

    const handleSignOut = () =>
        confirmAlert({
            title: t('Sign out'),
            message: t(
                'Are you sure you want to sign out? You will not be able to sign in unless you are connected to the internet.'
            ),
            buttons: [
                {
                    label: t('Cancel'),
                    onClick: () => {}
                },
                {
                    label: t('Sign out'),
                    onClick: async () => {
                        if (auth_type === 'cognito') {
                            await Auth.signOut()
                        }
                        appRoot.signOut()
                    }
                }
            ]
        })

    const signOutButton = (
        <button type="button" className="btn btn-primary styled-primary-button" onClick={handleSignOut}>
            <span className="fas fa-sign-out-alt fa-lg" /> {t('Sign out')}
        </button>
    )

    return (
        <Navbar className="navbar navbar-default app-navbar-bottom-margin" bg="light">
            <div className="navbar-left">
                <div className="navbar-header-title navbar-header navbar-brand">{avttHeader()}</div>
                {rt && <ProjectSelector currentRoot={rt} selectProject={selectProject} />}
            </div>
            <div className="navbar-header-icons navbar-header">
                {rt && !useMobileLayout && (
                    <>
                        <Link to="/index.html" className="app-selector-icon video-camera-button">
                            <HeadsetIcon
                                className={classNames(
                                    'top-navigation-icon',
                                    'edit-passages-button',
                                    'main-headset-icon',
                                    {
                                        'app-selector-disabled': !hasPortions
                                    }
                                )}
                                tooltip={t('recordingPlayRecord')}
                            />
                        </Link>
                        {!rt.iAmBackTranslator && (
                            <>
                                <SlttHelp id="status" tooltip={t('Edit project status.')} place="bottom">
                                    <Link to="/status" className="app-selector-icon">
                                        <i
                                            className={classNames(
                                                'top-navigation-icon',
                                                'fa-list-ol',
                                                'fa',
                                                'fa-2x',
                                                'edit-status-button',
                                                {
                                                    'fa-border': selected === 'status',
                                                    'app-selector-disabled': !hasPortions
                                                }
                                            )}
                                            onClick={() => {
                                                selectPage('/status')
                                            }}
                                        />
                                    </Link>
                                </SlttHelp>

                                <SlttHelp id="portions" tooltip={t('Edit portions.')} place="bottom">
                                    <Link to="/portions" className="app-selector-icon edit-portions-button">
                                        <i
                                            className={classNames(
                                                'top-navigation-icon',
                                                'fa-chart-pie',
                                                'fas',
                                                'fa-2x',
                                                {
                                                    'fa-border': selected === 'portions'
                                                }
                                            )}
                                            onClick={() => {
                                                selectPage('/portions')
                                            }}
                                        />
                                    </Link>
                                </SlttHelp>

                                <SlttHelp id="biblical-terms" tooltip={t('projectBiblicalTerms')} place="bottom">
                                    <Link to="/key-terms" className="app-selector-icon">
                                        <i
                                            className={classNames('top-navigation-icon', 'fa-star', 'fas', 'fa-2x', {
                                                'fa-border': selected === 'key-terms'
                                            })}
                                            onClick={() => {
                                                selectPage('/key-terms')
                                            }}
                                        />
                                    </Link>
                                </SlttHelp>

                                {rt.project.isEngageEnabled && (
                                    <Link to="/project-review" className="app-selector-icon edit-portions-button">
                                        <i
                                            className={classNames(
                                                'top-navigation-icon',
                                                'fa-comments',
                                                'fas',
                                                'fa-2x',
                                                {
                                                    'fa-border': selected === 'project-review'
                                                }
                                            )}
                                            onClick={() => {
                                                selectPage('/project-review')
                                            }}
                                            title={t('projectReviewTitle')}
                                        />
                                    </Link>
                                )}

                                <SlttHelp id="settings" tooltip={t('Edit project settings.')} place="bottom">
                                    <Link to="/project-settings" className="app-selector-icon">
                                        <i
                                            className={classNames(
                                                'top-navigation-icon',
                                                'fa-cog',
                                                'fas',
                                                'fa-2x',
                                                'edit-project-settings-button',
                                                {
                                                    'fa-border': selected === 'project-settings'
                                                }
                                            )}
                                            onClick={() => {
                                                selectPage('/project-settings')
                                            }}
                                        />
                                    </Link>
                                </SlttHelp>
                            </>
                        )}
                        {rt.iAmAdmin && !Root.screenCaptureInProgress() && (
                            <Link to="/database" className="app-selector-icon">
                                <i
                                    className={classNames('top-navigation-icon', 'fa-database', 'fas', 'fa-2x', {
                                        'fa-border': selected === 'database'
                                    })}
                                    onClick={() => {
                                        selectPage('/database')
                                    }}
                                    data-toggle="tooltip"
                                    title="Database Records."
                                />
                            </Link>
                        )}

                        {(iAmRoot || iAmRootObserver) && !Root.screenCaptureInProgress() && (
                            <Link to="/projects" className="app-selector-icon">
                                <i
                                    className={classNames('top-navigation-icon', 'fa-server', 'fas', 'fa-2x', {
                                        'fa-border': selected === 'projects'
                                    })}
                                    onClick={() => {
                                        selectPage('/projects')
                                    }}
                                    data-toggle="tooltip"
                                    title="Projects on server."
                                />
                            </Link>
                        )}
                    </>
                )}
                <HelpLink className="top-navigation-icon app-selector-icon fa-2x" tooltip={t('Get help')} />
                {!useMobileLayout && <div className="navbar-brand">{getEmailPrefix(username)}</div>}
                <div className="app-selector-icon">{isOnline ? signOutButton : <OfflineMessage />}</div>
                <div className="app-selector-icon">
                    <LocaleSelector currentValue={i18n.language} chooseOption={(option) => appRoot.setLocale(option)} />
                </div>
            </div>
        </Navbar>
    )
})
