/* eslint-disable react/no-array-index-key */

import { FC } from 'react'

import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { getEmailPrefix } from './Helpers'
import { MembersIcon } from './Icons'
import { Member } from '../../models3/Member'
import { Passage } from '../../models3/Passage'
import { Project } from '../../models3/Project'

import './MemberDisplay.css'

type ImageSize = 'small' | 'large' | 'extra-large'
type MemberAvatarProps = {
    imageUrl: string
    variation: ImageSize
    onClick?: () => void
}

function getMemberAvatarClassName(imageSize: ImageSize) {
    let className = ''
    if (imageSize === 'extra-large') {
        className = 'member-image-container member-image-container-xl'
    } else if (imageSize === 'large') {
        className = 'member-image-container member-image-container-lg'
    } else if (imageSize === 'small') {
        className = 'member-image-container member-image-container-sm'
    }
    return className
}

function getDefaultImageClassName(imageSize: ImageSize) {
    let defaultImageClassName = ''
    if (imageSize === 'extra-large') {
        defaultImageClassName = 'default-member-image-xl'
    } else if (imageSize === 'large') {
        defaultImageClassName = 'default-member-image-lg'
    } else if (imageSize === 'small') {
        defaultImageClassName = 'default-member-image-sm'
    }
    return defaultImageClassName
}

const UserAvatar: FC<MemberAvatarProps> = observer(({ imageUrl, variation, onClick }) => {
    const className = getMemberAvatarClassName(variation)
    const defaultImageClassName = getDefaultImageClassName(variation)

    if (imageUrl) {
        return <img className={className} src={imageUrl} onClick={onClick} />
    }

    return <MembersIcon className={`${className} ${defaultImageClassName}`} tooltip="" />
})

type UsernameWithImageProps = {
    imageUrl: string
    username: string
    imageSize: ImageSize
    showFullName?: boolean
    onlyShowAvailableParts?: boolean
    onlyShowUsernameIfNoImage?: boolean
    onClick?: () => void
}

export const UsernameWithImage = ({
    onlyShowAvailableParts,
    onlyShowUsernameIfNoImage,
    imageSize,
    imageUrl,
    username,
    onClick
}: UsernameWithImageProps) => {
    if (onlyShowAvailableParts) {
        if (imageUrl) {
            return (
                <span className="member-display-full inline-flex-centered">
                    <span className="member-image-container-extra-space">
                        <UserAvatar imageUrl={imageUrl} variation={imageSize} onClick={onClick} />
                    </span>
                    {username}
                </span>
            )
        }

        return <span>{username}</span>
    }

    if (onlyShowUsernameIfNoImage) {
        if (imageUrl) {
            return (
                <span className="member-display inline-flex-centered">
                    <UserAvatar imageUrl={imageUrl} variation={imageSize} onClick={onClick} />
                </span>
            )
        }

        return <span>{username}</span>
    }

    return (
        <span className="member-display-full inline-flex-centered">
            <span className="member-image-container-extra-space">
                <UserAvatar imageUrl={imageUrl} variation={imageSize} onClick={onClick} />
            </span>
            {username}
        </span>
    )
}

type MemberDisplayProps = {
    member: Member
    imageSize: ImageSize
    showFullName?: boolean
    onlyShowAvailableParts?: boolean
    onlyShowUsernameIfNoImage?: boolean
}

export const MemberDisplay: FC<MemberDisplayProps> = observer(
    ({ member, imageSize, showFullName, onlyShowAvailableParts, onlyShowUsernameIfNoImage }) => {
        const { imageUrl, email } = member
        const username = showFullName ? email : getEmailPrefix(email)

        return (
            <UsernameWithImage
                imageUrl={imageUrl}
                username={username}
                imageSize={imageSize}
                onlyShowAvailableParts={onlyShowAvailableParts}
                onlyShowUsernameIfNoImage={onlyShowUsernameIfNoImage}
            />
        )
    }
)

const SimpleMemberDisplay = ({ title, showIcon = false }: { title: string; showIcon?: boolean }) => {
    return (
        <>
            {showIcon && <MembersIcon className="member-icon" tooltip="" />}
            <div className="member-display">{title}</div>
        </>
    )
}

type MemberSelectorProps = {
    project: Project
    passage: Passage
    currentUser: string
    enabled: boolean
}

export const MemberSelector: FC<MemberSelectorProps> = ({ project, passage, currentUser, enabled }) => {
    const { t } = useTranslation()
    const assignedMember = project.members.find((member) => member.email === passage.assignee)
    const isCurrentUser = assignedMember?.email === currentUser
    const assignableMembers = project.members.filter((member) => ['translator', 'admin'].includes(member.role))

    return (
        <span className={`member-selector-current-status ${isCurrentUser ? 'current-user' : ''}`}>
            {assignedMember ? (
                <SimpleMemberDisplay title={getEmailPrefix(assignedMember.email)} showIcon />
            ) : (
                enabled && <SimpleMemberDisplay title={t('Unassigned')} showIcon />
            )}
            {enabled && (
                <Dropdown>
                    <Dropdown.Toggle
                        id="member-selector"
                        title=""
                        className="sl-dropdown member-selector-dropdown styled-dropdown-select"
                    />
                    <Dropdown.Menu className="styled-dropdown-select-menu">
                        <Dropdown.Item onClick={() => passage.setAssignee('')}>
                            <SimpleMemberDisplay title={t('Unassigned')} />
                        </Dropdown.Item>
                        {assignableMembers.map((member, i) => (
                            <Dropdown.Item key={i} onClick={() => passage.setAssignee(member.email)}>
                                <SimpleMemberDisplay title={getEmailPrefix(member.email)} />
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </span>
    )
}
