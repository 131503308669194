import { useState, useEffect } from 'react'

import { fetchLanguageTags, LanguageTag } from '../resources/LanguageTags'

const useFetchLanguageTags = () => {
    const [languageTags, setLanguageTags] = useState<LanguageTag[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let isMounted = true

        const fetchData = async () => {
            try {
                setIsLoading(true)
                const tags = await fetchLanguageTags()
                if (isMounted) {
                    setLanguageTags(tags)
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Error fetching languages. Some AVTT features may not work properly.', error)
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false)
                }
            }
        }

        fetchData()

        return () => {
            isMounted = false
        }
    }, [])

    return { languageTags, isLoading }
}

export default useFetchLanguageTags
