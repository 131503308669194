import { FunctionComponent, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs'

import { DownloadReport } from './downloadReport/DownloadReport'
import MembersEditor from './members/MembersEditor'
import ProjectPreferences from './preferences/ProjectPreferences'
import { ProjectResources } from './projectResources/ProjectResources'
import ProjectReferences from './references/ProjectReferences'
import { Root } from '../../models3/Root'
import { LocalStorageKeys, supportEmail } from '../app/slttAvtt'
import { BackupProjectModal, RestoreProjectModal } from '../modals/project/BackupRestoreModal'
import ProjectsEditor from '../projects/ProjectsEditor'
import UploadReport from '../translation/UploadReport'
import { DownloadButton, UploadButton } from '../utils/Buttons'
import ErrorBoundary from '../utils/Errors'
import {
    CloudDownloadIcon,
    DownloadIcon,
    EnvelopeIcon,
    FileUploadIcon,
    MembersIcon,
    ProjectGroupsIcon,
    ReferencesIcon,
    UploadIcon,
    UsersCogIcon
} from '../utils/Icons'
import LoadingMessage from '../utils/InitializationMessage'
import { LibraryIcon } from '../utils/SVGRepoIcons'

import './ProjectSettings.css'

const AVTTContactUs = () => {
    const { t } = useTranslation()

    return (
        <div>
            <p>{t('contactSupportMessage', { supportEmail })}</p>
            <ul>
                <li>{t('Which version of Windows or macOS you are using')}</li>
                <li>{t('Which version of AVTT you are using')}</li>
                <li>{t('Steps to reproduce the problem')}</li>
                <li>{t('What you expected would happen')}</li>
                <li>{t('What actually happened')}</li>
            </ul>
        </div>
    )
}

const LastSuccessfulBackupMessage = ({ rt }: { rt: Root }) => {
    const { t } = useTranslation()
    const backupTimeMs = Number(localStorage.getItem(LocalStorageKeys.LAST_SUCCESSFUL_BACKUP))
    if (backupTimeMs <= 0) {
        return <p>{t('backupProjectNotDoneBefore')}</p>
    }
    return <p>{t('backupProjectLastTimestamp', { dateString: rt.dateFormatter.format(new Date(backupTimeMs)) })}</p>
}

interface ExportProjectProps {
    rt: Root
}

export const BackupRestoreProject = ({ rt }: ExportProjectProps) => {
    const { t } = useTranslation()
    const [backupModalOpen, setBackupModalOpen] = useState(false)
    const [restoreModalOpen, setRestoreModalOpen] = useState(false)
    return (
        <div>
            <h3>{t('backupRestoreProject')}</h3>
            {backupModalOpen && <BackupProjectModal rt={rt} setOpen={setBackupModalOpen} />}
            {restoreModalOpen && <RestoreProjectModal rt={rt} setOpen={setRestoreModalOpen} />}
            <LastSuccessfulBackupMessage rt={rt} />
            <span className="backup-project-wrapper">
                <DownloadButton
                    className="backup-project-icon"
                    tooltip={t('backupProject')}
                    onClick={() => setBackupModalOpen(true)}
                    enabled
                />
                {t('backupProject')}
            </span>
            {rt.iAmAdmin && (
                <span className="backup-project-wrapper">
                    <UploadButton
                        className="backup-project-icon"
                        tooltip={t('projectRestore')}
                        onClick={() => setRestoreModalOpen(true)}
                        enabled
                    />
                    {t('projectRestore')}
                </span>
            )}
        </div>
    )
}

interface IProjectSettings {
    rt: Root
}

const ProjectSettingsTabIndex = 'ProjectSettingsTabIndex'

const ProjectSettings: FunctionComponent<IProjectSettings> = observer(({ rt }) => {
    const { t } = useTranslation()
    const [tabIndex, setTabIndex] = useState(parseInt(rt.getDefault(ProjectSettingsTabIndex) || '0'))
    const { initialized, loadingMessage, group, groupProjects } = rt

    if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

    const onSelectTab = (index: number) => {
        rt.setDefault(ProjectSettingsTabIndex, index.toString())
        setTabIndex(index)
    }

    return (
        <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => {
                onSelectTab(index)
            }}
        >
            <TabList>
                <Tab key="members">
                    <MembersIcon
                        className="project-settings-icon project-settings-members-icon"
                        tooltip={t('Members')}
                    />
                </Tab>
                <Tab key="group-projects">
                    <ProjectGroupsIcon className="project-settings-icon" tooltip={t('Group Projects')} />
                </Tab>
                <Tab key="references">
                    <ReferencesIcon className="project-settings-icon" tooltip={t('References')} />
                </Tab>
                <Tab key="project-resources" className="project-settings-tab__tab">
                    <LibraryIcon className="project-resources-icon" tooltip={t('projectResources')} />
                </Tab>
                <Tab key="upload-report">
                    <UploadIcon className="project-settings-icon" tooltip={t('Upload Report')} />
                </Tab>
                <Tab key="download-report">
                    <CloudDownloadIcon className="project-settings-icon" tooltip={t('Download Report')} />
                </Tab>
                {rt.iAmTranslator && (
                    <Tab key="backup-restore-project">
                        <DownloadIcon className="project-settings-icon" tooltip={t('backupRestoreProject')} />
                        <FileUploadIcon className="project-settings-icon" tooltip={t('backupRestoreProject')} />
                    </Tab>
                )}
                <Tab key="project-preferences">
                    <UsersCogIcon className="project-settings-icon" tooltip={t('Project Preferences')} />
                </Tab>
                <Tab key="avtt-contact-us">
                    <EnvelopeIcon className="project-settings-icon" tooltip={t('Contact Us')} />
                </Tab>
            </TabList>

            <TabPanel key="members">
                <ErrorBoundary>
                    <MembersEditor rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel key="group-projects">
                <ErrorBoundary>
                    {!group && !groupProjects && <div>{t('This project is neither a group nor a group project.')}</div>}
                    {group && (
                        <div>
                            {t('This project is part of the following group: ')} {group}
                        </div>
                    )}
                    {groupProjects && <ProjectsEditor groupRoot={rt} />}
                </ErrorBoundary>
            </TabPanel>
            <TabPanel key="references">
                <ErrorBoundary>
                    <ProjectReferences rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel key="project-resources">
                <ErrorBoundary>
                    <ProjectResources rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel key="upload-report">
                <ErrorBoundary>
                    <UploadReport rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel key="download-report">
                <ErrorBoundary>
                    <DownloadReport rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            {rt.iAmTranslator && (
                <TabPanel key="backup-restore-project">
                    <ErrorBoundary>
                        <BackupRestoreProject rt={rt} />
                    </ErrorBoundary>
                </TabPanel>
            )}
            <TabPanel key="project-preferences">
                <ErrorBoundary>
                    <ProjectPreferences {...{ rt }} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel key="avtt-contact-us">
                <ErrorBoundary>
                    <AVTTContactUs />
                </ErrorBoundary>
            </TabPanel>
        </Tabs>
    )
})

export default ProjectSettings
