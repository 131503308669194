import { useTranslation } from 'react-i18next'

import LabeledFieldWithIcon from '../utils/LabeledFieldWithIcon'
import { SimpleTextInput } from '../utils/TextInput'

type PassageNameEditorProps = {
    passageName: string
    setPassageName: (name: string) => void
    nameError: string
    setNameError: (error: string) => void
    validatePassageName: (name: string) => string
    label?: string
}

export const PassageNameEditor = ({
    passageName,
    setPassageName,
    nameError,
    setNameError,
    validatePassageName,
    label
}: PassageNameEditorProps) => {
    const { t } = useTranslation()
    const setName = (value: string) => {
        setNameError(validatePassageName(value))
        setPassageName(value)
    }

    return (
        <LabeledFieldWithIcon
            iconName="fa-tag"
            title={label ?? t('Title')}
            field={<SimpleTextInput autoFocus value={passageName} setValue={setName} errorMessage={nameError} />}
        />
    )
}
