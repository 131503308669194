/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useCallback, useEffect } from 'react'

import { t } from 'i18next'
import { observer } from 'mobx-react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'

import { ProjectPlanEditor } from './plan/ProjectPlanEditor'
import { ProgressGraphPage } from './progress/ProgressGraph'
import ProgressReport from './progress/ProgressReport'
import StatusBoardEditor from './StatusBoardEditor'
import { FilterType, StatusFilter, useStatus } from './StatusContext'
import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import ErrorBoundary from '../utils/Errors'

export enum TabsList {
    STATUS,
    PLAN,
    PROGRESS,
    GRAPH
}

interface IStatusEditor {
    rt: Root
    initialIndex?: TabsList
}

export type FilteredPortionPassages = {
    portion: Portion
    passages: Passage[]
}

const StatusTabs = observer(({ rt }: IStatusEditor) => {
    const { projectStatus, updateProjectStatus } = useStatus()

    if (!projectStatus) {
        return null
    }

    const { statusTab, statusFilter } = projectStatus

    useEffect(() => {
        if (
            (statusFilter.filterType === 'portion' &&
                !rt.project.portions.find((prt) => prt._id === statusFilter.portionId)) ||
            (statusFilter.filterType === 'passage' &&
                !rt.project.passages.find((psg) => psg._id === statusFilter.passageId)) ||
            (statusFilter.filterType === 'statusShowCurrentPassage' && !rt.passage) ||
            (statusFilter.filterType === 'statusShowCurrentPortion' && !rt.portion)
        ) {
            updateProjectStatus({
                statusTab,
                statusFilter: { filterType: 'statusShowAll', currentValue: 'Show All' }
            })
        }
    }, [statusFilter, rt.passage, rt.portion, rt.project.passages, rt.project.portions, updateProjectStatus, statusTab])

    const getCurrentValue = (statusKey: FilterType, name?: string) => {
        switch (statusKey) {
            case 'statusShowAll':
                return 'Show All'
            case 'statusShowAssignedPassages':
                return 'myPassages'
            case 'statusShowCurrentPassage':
                return 'Current Passage'
            case 'statusShowCurrentPortion':
                return 'Current Portion'
            case 'passage':
            case 'portion':
                return name || ''
            default:
                return ''
        }
    }

    const setStatusAndReset = useCallback(
        (
            statusKey:
                | 'statusShowAll'
                | 'statusShowAssignedPassages'
                | 'statusShowCurrentPortion'
                | 'statusShowCurrentPassage'
        ) => {
            updateProjectStatus({
                statusTab,
                statusFilter: { filterType: statusKey, currentValue: getCurrentValue(statusKey) }
            })
        },
        [updateProjectStatus, statusTab]
    )

    const setShowAll = () => setStatusAndReset('statusShowAll')
    const setShowAssignedPassages = () => setStatusAndReset('statusShowAssignedPassages')
    const setShowCurrentPortion = useCallback(() => setStatusAndReset('statusShowCurrentPortion'), [setStatusAndReset])
    const setShowCurrentPassage = () => setStatusAndReset('statusShowCurrentPassage')

    const setCurrentPassage = (portion: Portion, passage: Passage) => {
        const filter: StatusFilter = {
            filterType: 'passage',
            currentValue: portion.getLongPassageName(passage),
            passageId: passage._id,
            portionId: portion._id
        }
        updateProjectStatus({
            statusTab,
            statusFilter: filter
        })
    }

    const setCurrentPortion = (portion: Portion) => {
        updateProjectStatus({
            statusTab,
            statusFilter: { filterType: 'portion', currentValue: portion.name, portionId: portion._id }
        })
    }

    const { project } = rt

    const getPortionPassages = () => {
        if (!rt.portion || !rt.passage) {
            return []
        }

        switch (statusFilter.filterType) {
            case 'statusShowAll':
                return project.portions.map((portion) => ({ portion, passages: portion.passages }))
            case 'statusShowAssignedPassages':
                return project.portions.map((portion) => ({
                    portion,
                    passages: portion.passages.filter((passage) => passage.assignee === rt.username)
                }))
            case 'statusShowCurrentPortion': {
                const portion = rt.portion!
                return [
                    {
                        portion,
                        passages: portion.passages
                    }
                ]
            }
            case 'statusShowCurrentPassage': {
                const portion = rt.portion!
                const passage = rt.passage!
                return [
                    {
                        portion,
                        passages: [passage]
                    }
                ]
            }
            case 'portion': {
                const portion = project.portions.find((prt) => prt._id === statusFilter.portionId)
                return [
                    {
                        portion: portion!,
                        passages: portion!.passages
                    }
                ]
            }
            case 'passage': {
                const portion = project.portions.find((prt) => prt._id === statusFilter.portionId)
                const passage = portion!.passages.find((psg) => psg._id === statusFilter.passageId)
                return [
                    {
                        portion: portion!,
                        passages: [passage!]
                    }
                ]
            }
            default:
                return []
        }
    }

    const portionPassages = getPortionPassages()

    const currentSelectedOption = ['Show All', 'myPassages', 'Current Portion', 'Current Passage'].includes(
        statusFilter.currentValue
    )
        ? `*${t(`${statusFilter.currentValue}`)}*`
        : statusFilter.currentValue

    const onSelect = (updatedStatusTab: TabsList) => {
        updateProjectStatus({ statusTab: updatedStatusTab, statusFilter })
    }

    return (
        <Tabs selectedIndex={statusTab} onSelect={onSelect}>
            <TabList>
                <Tab>{t('projectStatusHeading')}</Tab>
                <Tab>{t('projectPlanHeading')}</Tab>
                <Tab>{t('Progress')}</Tab>
                <Tab>{t('Graph')}</Tab>
            </TabList>

            <TabPanel>
                <ErrorBoundary>
                    <StatusBoardEditor
                        rt={rt}
                        currentSelectedOption={currentSelectedOption}
                        portionPassages={portionPassages}
                        setCurrentPassage={setCurrentPassage}
                        setCurrentPortion={setCurrentPortion}
                        setShowAll={setShowAll}
                        setShowAssignedPassages={setShowAssignedPassages}
                        setShowCurrentPortion={setShowCurrentPortion}
                        setShowCurrentPassage={setShowCurrentPassage}
                    />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel>
                <ErrorBoundary>
                    <ProjectPlanEditor rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel>
                <ErrorBoundary>
                    <ProgressReport rt={rt} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel>
                <ErrorBoundary>
                    <ProgressGraphPage
                        rt={rt}
                        currentSelectedOption={currentSelectedOption}
                        portionPassages={portionPassages}
                        setCurrentPassage={setCurrentPassage}
                        setCurrentPortion={setCurrentPortion}
                        setShowAll={setShowAll}
                        setShowAssignedPassages={setShowAssignedPassages}
                        setShowCurrentPortion={setShowCurrentPortion}
                        setShowCurrentPassage={setShowCurrentPassage}
                    />
                </ErrorBoundary>
            </TabPanel>
        </Tabs>
    )
})

export default StatusTabs
