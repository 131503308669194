/* eslint-disable react/jsx-props-no-spreading */
import { Component } from 'react'

import { t } from 'i18next'
import { observer } from 'mobx-react'
import { Droppable } from 'react-beautiful-dnd'

import PassageCard from './PassageCard'
import { ColumnData } from './types'
import { Root } from '../../models3/Root'
import { InternalProjectStatus } from '../../types'

import './TaskColumn.css'

interface ITaskColumn extends ColumnData {
    rt: Root
}

class TaskColumn extends Component<ITaskColumn> {
    render() {
        const { task, id, items, rt } = this.props
        const { iAmTranslatorForPassage } = rt

        const getDisplayedName = () => {
            if (task) {
                return task.displayedName
            }

            if (id === InternalProjectStatus.RECORDED) {
                return t('recordingExists')
            }

            return ''
        }

        return (
            <div className="column-container">
                <div className="task-column-header">
                    <h6>{getDisplayedName()}</h6>
                    <div className="task-details">{task?.details ?? ''}</div>
                </div>
                <Droppable droppableId={id} isDropDisabled={id === InternalProjectStatus.NOT_STARTED}>
                    {(provided) => (
                        <div className="task-list" ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => (
                                <PassageCard
                                    key={item.passage._id}
                                    passageData={item}
                                    index={index}
                                    rt={rt}
                                    updateDifficulty={item.passage.setDifficulty.bind(item.passage)}
                                    isDragDisabled={
                                        !iAmTranslatorForPassage(item.passage) ||
                                        id === InternalProjectStatus.NOT_STARTED
                                    }
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }
}

export default observer(TaskColumn)
