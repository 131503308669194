import { useState } from 'react'

import { Modal, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import useProjectProfiles from '../../../hooks/useProjectProfiles'
import { Project } from '../../../models3/Project'
import SelectCreatable, { SelectCreatableOption } from '../../select/SelectCreatable'
import { CancelButton, OKButton } from '../../utils/Buttons'
import { ErrorList } from '../../utils/ErrorList'

interface ModalProps {
    project: Project
    statusOptions: SelectCreatableOption[]
    partnerOptions: SelectCreatableOption[]
    countryOptions: SelectCreatableOption[]
    onProfilesUpdate: () => void
    closeModal: () => void
}

export const ProjectProfilesModal = ({
    project,
    statusOptions,
    partnerOptions,
    countryOptions,
    onProfilesUpdate,
    closeModal
}: ModalProps) => {
    const { t } = useTranslation()
    const { updateProjectProfiles, inProgress } = useProjectProfiles()

    const { name: projectName, projectProfiles } = project
    const [profiles, setProfiles] = useState(projectProfiles || {})
    const [error, setError] = useState<string | null>(null)

    const isDisabled = inProgress || Boolean(error)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        setProfiles((prevProfiles) => ({
            ...prevProfiles,
            [name]: value
        }))
    }

    const handleSelectChange = (name: string, selectedOption: { value: string; label: string } | null) => {
        setProfiles((prevProfiles) => ({
            ...prevProfiles,
            [name]: selectedOption ? selectedOption.value : ''
        }))
    }

    const handleSave = async () => {
        try {
            await updateProjectProfiles(projectName, profiles)
            project.projectProfiles = profiles
            onProfilesUpdate()
            closeModal()
        } catch (err) {
            setError((err as Error).message)
        }
    }

    return (
        <Modal show onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('projectProfilesEdit')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <ErrorList errors={[{ text: error, key: '' }]} />}
                <Form>
                    <Form.Group controlId="formStatus">
                        <Form.Label>{t('projectProfilesStatus')}</Form.Label>
                        <SelectCreatable
                            isDisabled={isDisabled}
                            value={profiles.status ? { value: profiles.status, label: profiles.status } : null}
                            onChange={(selectedOption) => handleSelectChange('status', selectedOption)}
                            options={statusOptions}
                        />
                    </Form.Group>
                    <Form.Group controlId="formPartner">
                        <Form.Label>{t('projectProfilesPartner')}</Form.Label>
                        <SelectCreatable
                            isDisabled={isDisabled}
                            value={profiles.partner ? { value: profiles.partner, label: profiles.partner } : null}
                            onChange={(selectedOption) => handleSelectChange('partner', selectedOption)}
                            options={partnerOptions}
                        />
                    </Form.Group>
                    <Form.Group controlId="formCountry">
                        <Form.Label>{t('projectProfilesCountry')}</Form.Label>
                        <SelectCreatable
                            isDisabled={isDisabled}
                            value={profiles.country ? { value: profiles.country, label: profiles.country } : null}
                            onChange={(selectedOption) => handleSelectChange('country', selectedOption)}
                            options={countryOptions}
                        />
                    </Form.Group>
                    <Form.Group controlId="formNotes">
                        <Form.Label>{t('projectProfilesNotes')}</Form.Label>
                        <Form.Control
                            disabled={isDisabled}
                            as="textarea"
                            name="notes"
                            value={profiles.notes || ''}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div className="button-row">
                    <OKButton
                        enabled={!isDisabled}
                        onClick={handleSave}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('Save')}
                    />
                    <CancelButton
                        enabled={!inProgress}
                        onClick={closeModal}
                        className="cancel-button"
                        tooltip={t('Cancel')}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
}
