import { useEffect, useMemo, useState } from 'react'

import { Button, Form, FormGroup, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { GenericIcon, LoadingIcon } from './Icons'
import useFetchLanguageTags from '../../hooks/useFetchLanguageTags'
import { Passage } from '../../models3/Passage'
import { Root } from '../../models3/Root'
import { getBookNames } from '../../resources/bookNames'
import { RefRange } from '../../resources/RefRange'
import { useBiblePericopes } from '../app/BiblePericopesContext'
import { LocaleSelector } from '../app/LocaleSelector'
import { useAppRoot } from '../app/RootContext'
import { PassageNameAndReferenceEditor } from '../passages/PassageNameAndReferenceEditor'
import { LanguageSelector } from '../projectSettings/preferences/LanguageSelector'
import { Option } from '../projectSettings/projectResources/MultiSelect'
import Select from '../select/Select'

import './FirstTimeSetupModal.css'

enum Steps {
    Language,
    ProjectLanguage,
    Book,
    Passages,
    Summary
}

const stepsOrder = [Steps.Language, Steps.ProjectLanguage, Steps.Book, Steps.Passages, Steps.Summary]

const getPreviousStep = (currentStep: Steps) => {
    const currentIndex = stepsOrder.indexOf(currentStep)
    if (currentIndex > 0) {
        return stepsOrder[currentIndex - 1]
    }
}

const getNextStep = (currentStep: Steps) => {
    const currentIndex = stepsOrder.indexOf(currentStep)
    if (currentIndex < stepsOrder.length - 1) {
        return stepsOrder[currentIndex + 1]
    }
}

export const PassageTypeSelector = ({
    references,
    passageName,
    setSelectedPericopes,
    selectedPericopeType,
    setSelectedPericopeType
}: {
    references: RefRange[]
    passageName: string
    setSelectedPericopes: (selected: Option<string>[]) => void
    selectedPericopeType: Option<string>[]
    setSelectedPericopeType: (selected: Option<string>[]) => void
}) => {
    const { getPericopesByReference, pericopeTypeOptions, isLoading } = useBiblePericopes()

    const srvPericopes = getPericopesByReference(
        references,
        [{ label: 'passage per Study Guide', value: 'srvPericopes' }],
        passageName
    )

    useEffect(() => {
        if (
            references.length &&
            srvPericopes.length === 0 &&
            selectedPericopeType.some((pericopeType) => pericopeType.value === 'srvPericopes')
        ) {
            setSelectedPericopeType([])
        }
    }, [references, setSelectedPericopeType, srvPericopes, selectedPericopeType])

    const setNewPericopeType = (pericopeType: Option<string>[]) => {
        setSelectedPericopeType(pericopeType)
        setSelectedPericopes([])
    }

    if (isLoading) {
        return <LoadingIcon className="" />
    }

    return (
        <form className="passage-recording-type-chooser-form">
            <FormGroup controlId="project-setup-use-predefined-passages">
                {pericopeTypeOptions(references).map((option) => (
                    <Form.Check
                        key={option.value}
                        type="radio"
                        inline
                        onChange={() => {
                            setNewPericopeType([option])
                        }}
                        checked={selectedPericopeType.map((o) => o.value).includes(option.value)}
                        label={option.label}
                        id={option.value}
                        disabled={option.disabled}
                    />
                ))}
            </FormGroup>
        </form>
    )
}

const BibleBookSelectorStep = ({
    bibleBookName,
    setBibleBookName
}: {
    bibleBookName?: string
    setBibleBookName: (bookName?: string) => void
}) => {
    const { t, i18n } = useTranslation()
    const bookNames = getBookNames(i18n.language)

    const bookOptions = bookNames.map((book) => ({
        value: book,
        label: book
    }))

    const selectedOption = bookOptions.find((option) => option.value === bibleBookName)

    return (
        <>
            <h5>{t('firstTimeSetupStepTwo')}</h5>
            <Select
                id="project-bible-book-selector"
                className="er-resources-select"
                options={bookOptions}
                value={selectedOption}
                placeholder={t('Choose one')}
                onChange={(selected) => {
                    if (selected) {
                        setBibleBookName(selected.value)
                    }
                }}
                isClearable={false}
                isSearchable
                filterOption={({ label }, query) => label.toLowerCase().includes(query.toLowerCase())}
            />
        </>
    )
}

const LanguageSelectorStep = () => {
    const { t, i18n } = useTranslation()
    const appRoot = useAppRoot()

    return (
        <>
            <h5>{t('firstTimeSetupStepOne')}</h5>
            <div>
                <LocaleSelector currentValue={i18n.language} chooseOption={(option) => appRoot.setLocale(option)} />
            </div>
        </>
    )
}

const ProjectLanguageSelectorStep = ({
    languageCode,
    setLanguageCode
}: {
    languageCode: string
    setLanguageCode: (value: string) => void
}) => {
    const { t } = useTranslation()
    const { languageTags } = useFetchLanguageTags()

    return (
        <>
            <h5>{t('firstTimeSetupProjectLanguage')}</h5>
            <LanguageSelector
                languageCode={languageCode}
                setLanguageCode={setLanguageCode}
                languageTags={languageTags}
            />
        </>
    )
}

const PassageSelectorStep = ({
    isMultipleAdd,
    setIsMultipleAdd,
    portionReferences,
    portionName,
    selectedPericopeType,
    setSelectedPericopeType,
    passageName,
    setPassageName,
    setReferences,
    references,
    referencesError,
    setReferencesError,
    rt,
    nameError,
    setNameError
}: {
    isMultipleAdd: boolean
    setIsMultipleAdd: (value: boolean) => void
    portionReferences: RefRange[]
    portionName?: string
    selectedPericopeType: Option<string>[]
    setSelectedPericopeType: (value: Option<string>[]) => void
    passageName: string
    setPassageName: (value: string) => void
    setReferences: (value: RefRange[]) => void
    references: RefRange[]
    referencesError: boolean
    setReferencesError: (value: boolean) => void
    rt: Root
    nameError: string
    setNameError: (value: string) => void
}) => {
    const { t } = useTranslation()

    const validatePassageName = (value: string) => {
        const name = value.trim()
        if (name === '') {
            return t('Empty name')
        }
        return ''
    }

    return (
        <>
            <h5>{t('firstTimeSetupStepThree', { portionName })}</h5>
            <form className="passage-recording-type-chooser-form">
                <FormGroup controlId="project-setup-use-predefined-passages">
                    {[t('Yes'), t('No')].map((option) => (
                        <Form.Check
                            key={option}
                            type="radio"
                            inline
                            onChange={() => {
                                setIsMultipleAdd(option === t('Yes'))
                            }}
                            checked={isMultipleAdd === (option === t('Yes'))}
                            label={option}
                            id={option}
                        />
                    ))}
                </FormGroup>
            </form>
            <div className="project-setup-modal__passage-add-wrapper">
                {isMultipleAdd ? (
                    <>
                        <div className="project-setup-modal__passage-divide-question">
                            {t('firstTimeSetupStepThreePericopeType')}
                        </div>
                        <PassageTypeSelector
                            references={portionReferences}
                            passageName={portionName ?? ''}
                            setSelectedPericopes={() => {}}
                            selectedPericopeType={selectedPericopeType}
                            setSelectedPericopeType={setSelectedPericopeType}
                        />
                    </>
                ) : (
                    <>
                        <div className="project-setup-modal__passage-divide-question">
                            {t('firstTimeSetupStepThreeSinglePassageInstructions')}
                        </div>
                        <PassageNameAndReferenceEditor
                            isAdd
                            isMultipleAdd={false}
                            passageName={passageName}
                            setNameError={setNameError}
                            setPassageName={setPassageName}
                            setSelectedPericopes={() => {}}
                            references={references}
                            setReferences={setReferences}
                            nameError={nameError}
                            referencesError={referencesError}
                            setReferencesError={setReferencesError}
                            rt={rt}
                            validatePassageName={validatePassageName}
                        />
                    </>
                )}
            </div>
        </>
    )
}

const SummaryStep = () => {
    const { t } = useTranslation()
    return <h5>{t('firstTimeSetupSummary')}</h5>
}

const ModalBodyAndFooter = ({
    pericopeTypeOptions,
    getPericopesByReference,
    setIsModalOpen
}: {
    pericopeTypeOptions: (references: RefRange[]) => Option<string>[]
    getPericopesByReference: (
        references: RefRange[],
        selectedPericopeType: Option<string>[],
        passageName: string
    ) => Option<string>[]
    setIsModalOpen: (value: boolean) => void
}) => {
    const { t, i18n } = useTranslation()
    const { dir, language } = i18n
    const appRoot = useAppRoot()
    const history = useHistory()
    const [step, setStep] = useState(stepsOrder[0])
    const [languageCode, setLanguageCode] = useState('')
    const [portionName, setPortionName] = useState<string>()
    const [isMultipleAdd, setIsMultipleAdd] = useState(true)
    const [selectedPericopeType, setSelectedPericopeType] = useState<Option<string>[]>([])
    const [passageName, setPassageName] = useState('')
    const [references, setReferences] = useState<RefRange[]>([])
    const [referencesError, setReferencesError] = useState(false)
    const [nameError, setNameError] = useState('')
    const [didSubmit, setDidSubmit] = useState(false)

    const { rt } = appRoot
    const portionReferences = useMemo(
        () => (portionName ? rt?.parseReferences(portionName, true) ?? [] : []),
        [portionName, rt]
    )

    useEffect(() => {
        const options = pericopeTypeOptions(portionReferences).filter((option) => !option.disabled)
        setSelectedPericopeType(options.length > 0 ? [options[0]] : [])
    }, [pericopeTypeOptions, portionReferences])

    // Run when portion name changes
    useEffect(() => {
        setPassageName('')
        setReferences([])
        setIsMultipleAdd(true)
    }, [portionName])

    if (!rt) {
        return null
    }

    const canGoForward = () => {
        if (step === Steps.Language) {
            return true
        }

        if (step === Steps.ProjectLanguage) {
            return !!languageCode
        }

        if (step === Steps.Book) {
            return !!portionName
        }

        if (step === Steps.Passages) {
            if (isMultipleAdd) {
                return selectedPericopeType.length > 0
            }
            return !!passageName && !nameError && !referencesError
        }

        return true
    }

    const addPassages = async () => {
        const passagesAdded: Passage[] = []
        const { portion } = rt
        if (!portion) {
            return passagesAdded
        }

        if (isMultipleAdd) {
            const pericopes = getPericopesByReference(portionReferences, selectedPericopeType, portionName ?? '')
            for (const { value, label } of pericopes) {
                const [startRef, endRef] = value.split('-')
                const passage = await portion.addNewPassage({
                    name: label,
                    references: [new RefRange(startRef, endRef)],
                    checkDuplicate: false
                })
                passagesAdded.push(passage)
            }
        } else {
            const passage = await portion.addNewPassage({ name: passageName, references })
            passagesAdded.push(passage)
        }

        return passagesAdded
    }

    const onOK = async () => {
        setDidSubmit(true)
        if (!portionName) {
            setIsModalOpen(false)
            return
        }

        await rt.project.setLanguage(languageCode)
        await rt.project.prepopulateBookNames(i18n.language)
        await rt.saveNewPortion(portionName, portionReferences)
        const passagesAdded = await addPassages()
        if (passagesAdded.length) {
            rt.setPassage(passagesAdded[0])
        }
        setIsModalOpen(false)
        history.push('/')
    }

    const goToPreviousStep = () => {
        const previousStep = getPreviousStep(step)
        if (previousStep !== undefined) {
            setStep(previousStep)
        }
    }

    const goToNextStep = () => {
        const nextStep = getNextStep(step)
        if (nextStep !== undefined) {
            setStep(nextStep)
        }
    }

    const languageDirection = dir(language)

    return (
        <>
            <Modal.Header>
                <Modal.Title>{t('firstTimeSetupHeader')}</Modal.Title>
                <div className="project-setup-modal__step-progress">
                    {t('stepProgress', { currentStep: step + 1, totalSteps: stepsOrder.length })}
                </div>
            </Modal.Header>
            <Modal.Body>
                {step === Steps.Language && <LanguageSelectorStep />}
                {step === Steps.ProjectLanguage && (
                    <ProjectLanguageSelectorStep languageCode={languageCode} setLanguageCode={setLanguageCode} />
                )}
                {step === Steps.Book && (
                    <BibleBookSelectorStep bibleBookName={portionName} setBibleBookName={setPortionName} />
                )}
                {step === Steps.Passages && (
                    <PassageSelectorStep
                        isMultipleAdd={isMultipleAdd}
                        setIsMultipleAdd={setIsMultipleAdd}
                        portionReferences={portionReferences}
                        portionName={portionName}
                        selectedPericopeType={selectedPericopeType}
                        setSelectedPericopeType={setSelectedPericopeType}
                        passageName={passageName}
                        setPassageName={setPassageName}
                        setReferences={setReferences}
                        references={references}
                        referencesError={referencesError}
                        setReferencesError={setReferencesError}
                        rt={rt}
                        nameError={nameError}
                        setNameError={setNameError}
                    />
                )}
                {step === Steps.Summary && !didSubmit && <SummaryStep />}
                {didSubmit && <LoadingIcon className="" />}
            </Modal.Body>
            {!didSubmit && (
                <Modal.Footer className="project-setup-modal__footer">
                    <div className="project-setup-modal__previous-button-wrapper">
                        {step === Steps.Language ? (
                            <span />
                        ) : (
                            <Button
                                variant="light"
                                onClick={goToPreviousStep}
                                className="project-setup-modal__footer-button"
                            >
                                <GenericIcon
                                    enabled
                                    iconName={languageDirection === 'rtl' ? 'fa-arrow-right' : 'fa-arrow-left'}
                                    className="project-setup-modal__footer-icon"
                                    tooltip=""
                                />
                            </Button>
                        )}
                    </div>
                    <div className="project-setup-modal__next-button-wrapper">
                        {step === Steps.Summary ? (
                            <Button
                                variant="success"
                                className="project-setup-modal__footer-button"
                                onClick={() => onOK()}
                                title={t('getStarted')}
                            >
                                {t('getStarted')}
                                <GenericIcon
                                    iconName="fa-check"
                                    className="project-setup-modal__footer-icon"
                                    tooltip={t('getStarted')}
                                    enabled
                                />
                            </Button>
                        ) : (
                            <Button
                                variant="primary"
                                disabled={!canGoForward()}
                                className="project-setup-modal__footer-button"
                                onClick={goToNextStep}
                                title={t('continue')}
                            >
                                {t('continue')}
                                <GenericIcon
                                    enabled
                                    iconName={languageDirection === 'rtl' ? 'fa-arrow-left' : 'fa-arrow-right'}
                                    className="project-setup-modal__footer-icon"
                                    tooltip={t('continue')}
                                />
                            </Button>
                        )}
                    </div>
                </Modal.Footer>
            )}
        </>
    )
}

export const FirstTimeSetupModal = ({ setIsModalOpen }: { setIsModalOpen: (value: boolean) => void }) => {
    const { t } = useTranslation()
    const { pericopeTypeOptions, getPericopesByReference, isLoading } = useBiblePericopes()

    return (
        <Modal show backdrop="static">
            {isLoading ? (
                <>
                    <Modal.Header>
                        <Modal.Title>{t('firstTimeSetupHeader')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LoadingIcon className="" />
                    </Modal.Body>
                </>
            ) : (
                <ModalBodyAndFooter
                    pericopeTypeOptions={pericopeTypeOptions}
                    getPericopesByReference={getPericopesByReference}
                    setIsModalOpen={setIsModalOpen}
                />
            )}
        </Modal>
    )
}
