import { t } from 'i18next'
import { observable, computed } from 'mobx'

import { DBObject } from './DBObject'
import { IDB } from './IDB'
import { ProjectStage } from './ProjectStage'
import { InternalProjectStatus } from '../types'

export class ProjectTask extends DBObject {
    @observable name = ''

    @observable details = ''

    @observable difficulty = 1.0

    @observable rank = ''

    // id is tricky.
    // It is set once and never changed.
    // Its name is very similar to _id (which is different unique identifier for this task)
    // It has the same format as number you see in UI for this task but will often be
    // a different value if tasks/stages have been added/deleted.
    // this is the value that is stored in status field of PassageVideo.
    @observable id = '' // short unique identifier, e.g., 1.2.

    @observable stagePosition = 0 // position of stage in list of stages. Non-persisted

    @observable taskPosition = 0 // position in containing list of tasks. Non-persisted

    @computed get displayedName() {
        const { name, stagePosition, taskPosition } = this
        if (name === InternalProjectStatus.NOT_STARTED) {
            return t('Not started')
        }

        if (name === InternalProjectStatus.FINISHED) {
            return t('Finished')
        }

        return `${stagePosition}.${taskPosition} ${name}`
    }

    // We used to include that stage/task number in the name, remove this if still present
    static validName(name: string) {
        return name.replace(/^(\d+\.?\d*\s*)*/, '')
    }

    constructor(_id: string, db: IDB) {
        super(_id, db)
        this.setDetails = this.setDetails.bind(this)
        this.setDifficulty = this.setDifficulty.bind(this)
        this.setName = this.setName.bind(this)
    }

    dbg() {
        const { name, difficulty, rank, id, stagePosition, taskPosition } = this
        return { name, difficulty, rank, id, stagePosition, taskPosition }
    }

    toDocument() {
        const { name, rank, details, difficulty, id } = this
        return this._toDocument({ name, rank, details, difficulty, id, model: 8 })
    }

    toSnapshot(): any {
        const { name, details, difficulty, id, stagePosition, taskPosition } = this
        return { name, details, difficulty, id, stagePosition, taskPosition }
    }

    async setRank(rankNumber: number) {
        this.rank = DBObject.numberToRank(rankNumber)
        const doc = this.toDocument()
        await this.db.put(doc)
    }

    async setName(stage: ProjectStage, name: string) {
        name = name.trim()

        name = ProjectTask.validName(name)
        // Don't allow setting a duplicate name
        if (stage.tasks.find((task) => task.name === name)) {
            return
        }

        const doc = this._toDocument({ name, model: 8 })
        await this.db.put(doc)
    }

    async setDetails(details: string) {
        details = details.trim()
        if (details === this.details) {
            return
        }
        const doc = this._toDocument({ details, model: 8 })
        await this.db.put(doc)
    }

    async setId(id: string) {
        id = id.trim()
        if (id === this.id) {
            return
        }
        const doc = this._toDocument({ id, model: 8 })
        await this.db.put(doc)
    }

    async setDifficulty(difficulty: number) {
        if (difficulty < 0.0 || difficulty === this.difficulty) {
            return
        }
        const doc = this._toDocument({ difficulty, model: 8 })
        await this.db.put(doc)
    }
}
