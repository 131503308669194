import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { EditableProjectStage } from './EditableProjectStage'
import ProjectStageAdder from './ProjectStageAdder'
import { ProjectStage } from '../../../models3/ProjectStage'
import { Root } from '../../../models3/Root'
import { InternalProjectStatus } from '../../../types'
import { displayError } from '../../utils/Errors'

interface IStage {
    index: number
    stage: ProjectStage
    addStage: (name: string, index: number) => void
    deleteStage: (_id: string) => void
    rt: Root
}

const Stage = observer(({ index, stage, addStage, deleteStage, rt }: IStage) => {
    const { iAmAdmin } = rt
    const { plans } = rt.project
    if (!plans.length) {
        return null
    }

    const plan = plans[0]
    return (
        <div className="stage">
            {iAmAdmin && index === 0 && (
                <ProjectStageAdder plan={plan} addStage={(newStage) => addStage(newStage, index)} />
            )}
            <EditableProjectStage stage={stage} deleteStage={() => deleteStage(stage._id)} rt={rt} />
            {iAmAdmin && <ProjectStageAdder plan={plan} addStage={(newStage) => addStage(newStage, index + 1)} />}
        </div>
    )
})

interface IEditableStageList {
    rt: Root
}

export const EditableStageList = observer(({ rt }: IEditableStageList) => {
    const { t } = useTranslation()
    const addStage = async (name: string, index: number) => {
        const { plans } = rt.project

        if (!plans.length) {
            return
        }

        const plan = plans[0]
        if (plan.stages.find((stage) => stage.name === InternalProjectStatus.NOT_STARTED)) {
            // 'Not started' is the 1st stage
            index += 1
        }

        // Ensure stage added before 'Finished' stage
        index = Math.min(index, plan.stages.length - 1)
        try {
            await plan.addStage(index, name)
        } catch (error) {
            displayError(error)
        }
    }

    const deleteStage = (_id: string) => {
        const { project } = rt
        if (project.plans.length) {
            project.plans[0].removeStage(project, _id)
        }
    }

    const { iAmAdmin } = rt
    const { plans } = rt.project

    if (!plans.length) {
        return null
    }
    const plan = plans[0]

    const viewableStages = plan.viewableStages || []
    return (
        <div>
            {viewableStages.length > 0 ? (
                <div className="project-plan">
                    {viewableStages.map((stage, index) => (
                        <Stage
                            key={stage._id}
                            index={index}
                            stage={stage}
                            addStage={addStage}
                            deleteStage={deleteStage}
                            rt={rt}
                        />
                    ))}
                </div>
            ) : (
                <div>
                    {iAmAdmin && (
                        <>
                            <div>{t('projectPlanDescription')}</div>
                            <ProjectStageAdder plan={plan} addStage={(name) => addStage(name, 0)} />
                        </>
                    )}
                </div>
            )}
        </div>
    )
})

export default Stage
