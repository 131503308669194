/* eslint-disable react/jsx-props-no-spreading */
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { ReviewProjectGeneralQuestion } from '../../models3/ReviewProjectGeneralQuestion'
import { ReviewRecordingMediaPlayer } from '../audio/ReviewRecordingMediaPlayer'
import { PencilButton } from '../utils/Buttons'
import { DragAndDropIcon } from '../utils/Icons'
import { Switch } from '../utils/Switch'

import './ProjectReview.css'

export const GeneralQuestionSortableItem = observer(
    ({
        avttProjectName,
        question,
        editItem,
        iAmInterpreter
    }: {
        avttProjectName: string
        question: ReviewProjectGeneralQuestion
        editItem: () => void
        iAmInterpreter: boolean
    }) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: question._id })
        const { t } = useTranslation()

        const { text, fileName, isActive } = question

        return (
            <div ref={setNodeRef} style={{ transform: CSS.Transform.toString(transform), transition }}>
                <div className="general-question-sortable-container-item">
                    {iAmInterpreter && (
                        <div {...attributes} {...listeners}>
                            <DragAndDropIcon className="fa-lg" />
                        </div>
                    )}
                    <span className={`general-question-title ${isActive ? '' : 'hidden-item'}`}>{text}</span>
                    <span className="general-question-audio">
                        {avttProjectName && fileName && (
                            <ReviewRecordingMediaPlayer avttProjectName={avttProjectName} url={fileName} />
                        )}
                    </span>
                    <span className="general-question-buttons">
                        <PencilButton
                            enabled={iAmInterpreter}
                            onClick={() => {
                                editItem()
                            }}
                            className="project-review-button default-blue-icon general-question-edit-button"
                            tooltip={t('Edit')}
                        />
                        <Switch
                            enabled={iAmInterpreter}
                            value={isActive}
                            setValue={async (value) => {
                                await question.setIsActive(value)
                            }}
                            tooltip={t('showOrHide')}
                        />
                    </span>
                </div>
            </div>
        )
    }
)
