import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { ReviewProjectGeneralQuestion } from './ReviewProjectGeneralQuestion'
import { ReviewProjectPassageRecording } from './ReviewProjectPassageRecording'
import { ReviewProjectProfileAttribute } from './ReviewProjectProfileAttribute'

export const isProjectCodeValid = (code: string) => {
    return code.trim().length >= 6
}

export class ReviewProject extends DBObject {
    @observable projectKey = ''

    @observable projectCode = ''

    @observable title = ''

    @observable profileAttributes: ReviewProjectProfileAttribute[] = []

    @observable generalQuestions: ReviewProjectGeneralQuestion[] = []

    @observable passageRecordings: ReviewProjectPassageRecording[] = []

    @observable isActive = false

    private static model = 22

    toDocument() {
        const { projectKey, projectCode, title, isActive } = this
        return this._toDocument({ projectKey, projectCode, title, model: ReviewProject.model, isActive })
    }

    // ReviewProject is used in the AVTT review app. To make things easier, we save the entire
    // state to a document any time the state changes, instead of just the parts that changed.
    async setValues({ projectKey, projectCode, title }: { projectKey: string; projectCode: string; title: string }) {
        if (projectKey === this.projectKey && projectCode === this.projectCode && title === this.title) {
            return
        }
        const document = this.toDocument()
        document.projectKey = projectKey
        document.projectCode = projectCode
        document.title = title
        await this.db.put(document)
    }

    createProfileAttribute(creationDate: Date) {
        const newId = this.db.getNewId(this.profileAttributes, creationDate, 'rpa_')
        const attribute = new ReviewProjectProfileAttribute(`${this._id}/${newId}`, this.db)
        let rank = 100
        if (this.profileAttributes.length > 0) {
            rank = this.profileAttributes.slice(-1)[0].rankAsNumber + 100
        }
        attribute.rank = DBObject.numberToRank(rank)
        return attribute
    }

    createGeneralQuestion(creationDate: Date) {
        const newId = this.db.getNewId(this.generalQuestions, creationDate, 'rpgq_')
        const question = new ReviewProjectGeneralQuestion(`${this._id}/${newId}`, this.db)
        const largestExistingRank = this.generalQuestions.length ? this.generalQuestions.slice(-1)[0].rankAsNumber : 0
        const rank = 100 + largestExistingRank
        question.rank = DBObject.numberToRank(rank)
        return question
    }

    async setIsActive(isActive: boolean) {
        if (isActive === this.isActive) {
            return
        }
        const doc = this.toDocument()
        doc.isActive = isActive
        await this.db.put(doc)
    }

    createReviewRecording(creationDate: Date) {
        const newId = this.db.getNewId(this.passageRecordings, creationDate, 'rppr_')
        const itemId = `${this._id}/${newId}`
        const recording = new ReviewProjectPassageRecording(itemId, this.db)
        const largestExistingRank = this.passageRecordings.length ? this.passageRecordings.slice(-1)[0].rankAsNumber : 0
        const rank = 100 + largestExistingRank
        recording.rank = DBObject.numberToRank(rank)
        return recording
    }

    async addPassageRecording(recording: ReviewProjectPassageRecording) {
        await this.db.put(recording.toDocument())
    }

    isProjectCodeValid() {
        return isProjectCodeValid(this.projectCode)
    }
}
