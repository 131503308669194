import { useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { isProjectCodeValid, ReviewProject } from '../../models3/ReviewProject'
import { CancelButton, OKButton } from '../utils/Buttons'
import LabeledFieldWithIcon from '../utils/LabeledFieldWithIcon'
import { SimpleTextInput } from '../utils/TextInput'

import './ProjectReview.css'

interface GeneralConfigModalProps {
    closeModal: () => void
    project: ReviewProject
}

export const GeneralConfigModal = ({ closeModal, project }: GeneralConfigModalProps) => {
    const { t } = useTranslation()

    const getProjectCodeError = (value: string) => {
        if (!isProjectCodeValid(value)) {
            return t('projectReviewSecretCodeLengthError')
        }
        return ''
    }

    const [projectCode, setProjectCode] = useState(project.projectCode)
    const [projectCodeError, setProjectCodeError] = useState(getProjectCodeError(project.projectCode))
    const [title, setTitle] = useState(project.title)

    const handleSetProjectCode = (value: string) => {
        setProjectCode(value)
        const error = getProjectCodeError(value)
        setProjectCodeError(error)
    }

    return (
        <Modal show onHide={closeModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('projectReviewGeneralConfigurationTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LabeledFieldWithIcon
                    iconName="fa-tag"
                    title={t('Title')}
                    field={<SimpleTextInput value={title} setValue={setTitle} errorMessage="" />}
                />
                <LabeledFieldWithIcon
                    iconName="fa-key"
                    title={t('projectReviewKey')}
                    field={
                        <SimpleTextInput
                            value={project.projectKey}
                            setValue={() => {}}
                            errorMessage=""
                            enabled={false}
                        />
                    }
                />
                <LabeledFieldWithIcon
                    iconName="fa-lock"
                    title={t('projectReviewCode')}
                    field={
                        <SimpleTextInput
                            value={projectCode}
                            setValue={handleSetProjectCode}
                            errorMessage={projectCodeError}
                        />
                    }
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="project-review-modal-footer button-row">
                    <OKButton
                        enabled={!projectCodeError}
                        onClick={async () => {
                            await project.setValues({
                                projectKey: project.projectKey,
                                projectCode: projectCode.trim(),
                                title
                            })
                            closeModal()
                        }}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                    <CancelButton enabled onClick={closeModal} className="cancel-button" tooltip={t('Cancel')} />
                </div>
            </Modal.Footer>
        </Modal>
    )
}
