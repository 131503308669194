import { EventEmitter } from 'events'

import { observable, computed } from 'mobx'

import { Passage } from './Passage'
import { IDrawablePassageGloss } from './PassageGloss'
import { PassageNote } from './PassageNote'
import { PassageVideo } from './PassageVideo'
import { Portion } from './Portion'
import { ReferenceMarker } from './ReferenceMarker'
import { TrashCan } from './TrashCan'
import { VideoCache } from './VideoCache'
import { fmt } from '../components/utils/Fmt'
import { ViewableVideoCollection } from '../components/video/ViewableVideoCollection'
import { RefRange } from '../resources/RefRange'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:RootBase')

export class TimelineSelection {
    @observable start = 0

    @observable end = 0

    constructor(start: number, end: number) {
        this.start = start
        this.end = end
    }
}

export class Timeline {
    @observable zoomLevel = 1

    @observable start = 0

    @observable end = 0

    @computed get duration() {
        return this.end - this.start
    }

    @observable selection?: TimelineSelection

    getSelectionTimes() {
        const selectionStartTime = this.selection?.start ?? -1
        const selectionEndTime = this.selection?.end ?? -1
        return { selectionStartTime, selectionEndTime }
    }

    setZoomLevel(zoom: number) {
        if (zoom < 1.999) zoom = 1
        this.zoomLevel = zoom
    }

    selectionPresent() {
        return !!this.selection && this.selection.start >= 0 && this.selection.end > this.selection.start
    }

    // Ensure that start/end point of time shown contains current time.
    adjustZoomLimits(forceAdjustment: boolean, currentTime: number, duration: number) {
        const { zoomLevel, start, end } = this

        if (zoomLevel === 1) {
            if (start !== 0 || end !== duration) {
                this.start = 0
                this.end = duration
            }
            return
        }

        if (currentTime >= start && currentTime <= end && !forceAdjustment) return

        const durationShown = duration / zoomLevel
        this.start = Math.max(0, currentTime - durationShown / 4)
        this.end = Math.min(duration, currentTime + durationShown)
        log(`adjustTimelineZoomLimits ${start.toFixed(2)}..${end.toFixed(2)}`)
    }
}

export class RootBase extends EventEmitter {
    @observable passage: Passage | null = null

    @observable note?: PassageNote

    @observable mostRecentNoteIdViewed = ''

    // When this is set the reference editor is open in the video toolbar
    @observable verseReference?: ReferenceMarker

    @observable playing = false

    @observable currentTime = 0.0

    @observable passageVideo: PassageVideo | null = null

    @observable currentVideos = new ViewableVideoCollection() // Info about the videos that make up passageVideo

    @observable duration = 0.0

    @observable dbsRefs: RefRange[] = []

    @observable passageReferences: RefRange[] = []

    @observable timeline: Timeline = new Timeline() // start/end/zoom of VideoPositionBar

    @observable glossScale = 0.0 // 0..100, 0 is narrow width glosses is GlossBar

    // Gloss currently being edited, if any
    @observable drawableGloss: IDrawablePassageGloss | null = null // Gloss currently being edited, if any

    rootVideoPlayerListenersAdded = false

    @observable trashCan = new TrashCan()

    constructor() {
        super()
        this.setDuration = this.setDuration.bind(this)
        this.setDrawableGloss = this.setDrawableGloss.bind(this)
        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
        this.stop = this.stop.bind(this)
        this.adjustCurrentTime = this.adjustCurrentTime.bind(this)
        this.resetCurrentTime = this.resetCurrentTime.bind(this)
        this.setCurrentTime = this.setCurrentTime.bind(this)
        this.setPlaying = this.setPlaying.bind(this)
        this.setGlossScale = this.setGlossScale.bind(this)
        this._setPassageVideo = this._setPassageVideo.bind(this)
        this.createNoteIfNonexistent = this.createNoteIfNonexistent.bind(this)
        this.setDbsRefs = this.setDbsRefs.bind(this)
        this.setNote = this.setNote.bind(this)
    }

    @computed get canPlayThrough() {
        return this.currentVideos.downloaded
    }

    @computed get currentURLs() {
        return this.currentVideos.viewableVideos.reduce((prev, vv) => prev + vv.video.url, '')
    }

    setDuration(duration: number) {
        this.duration = duration
        this.timeline.adjustZoomLimits(true, this.currentTime, duration)
    }

    setDrawableGloss(gloss: IDrawablePassageGloss | null) {
        this.drawableGloss = gloss
    }

    /**
     * Play video in main window (RootVideoPlayer)
     * @param startTime - when present start playing video at this time, otherwise at time = 0
     * @param endPosition - when present stop playing video at this time, otherwise play to end
     * @param resetTime - when present, reset video time to this playing
     */
    play(startTime?: number, endPosition?: number, resetTime?: number) {
        log('PLAY', fmt({ startTime, endPosition, resetTime }))
        this.emit('play', startTime, endPosition, resetTime)
    }

    /**
     * Pause current video. Do not reset to specified reset time.
     */
    pause() {
        log('PAUSE')
        this.emit('pause')
    }

    stop() {
        log('STOP')
        this.emit('stop')
    }

    adjustCurrentTime(delta: number) {
        log(`adjustCurrentTime ${this.currentTime}, ${delta}`)
        this.resetCurrentTime(this.currentTime + delta)
    }

    resetCurrentTime(newTime: number, duration?: number) {
        log(`resetCurrentTime`, fmt({ newTime, duration }))
        if (duration !== undefined) {
            this.duration = duration // must happen before adjustTimelineZoomLimits
        }
        this.currentTime = newTime
        this.timeline.adjustZoomLimits(duration !== undefined, this.currentTime, this.duration)
        this.emit('setCurrentTime', newTime)
    }

    setCurrentTime(currentTime: number) {
        // log(`setCurrentTime=${currentTime}`)
        this.currentTime = currentTime
        this.timeline.adjustZoomLimits(false, this.currentTime, this.duration)
    }

    setPlaying(playing: boolean) {
        this.playing = playing
    }

    setGlossScale(scale: number) {
        // if (scale < 0) scale = 0
        // if (scale > 100) scale = 100
        this.glossScale = scale
        log(`setGlossScale ${scale.toFixed(2)}`)
    }

    // Return true if a selection is present and it can be patched.
    // If _displayError present, display reason why selection cannot be patched.
    patchableSelectionPresent(_displayError?: (message: string) => void) {
        if (!this.timeline.selectionPresent() || !this.passageVideo) {
            return false
        }

        return this.passageVideo.patchable(this.timeline, _displayError)
    }

    async _setPassageVideo(name: string, passage: Passage | null, passageVideo: PassageVideo | null) {
        log(`[${name}] setPassageVideo: ${passageVideo && passageVideo._id}`)

        this.currentTime = 0
        this.timeline.selection = undefined

        if (!passageVideo || !passage) {
            this.passageVideo = null
            return
        }

        this.currentVideos.reset()
        this.currentVideos.setup(passage, passageVideo)
        this.currentVideos.download().catch()

        log(`passageVideo.url`, passageVideo && passageVideo.url)
        passageVideo.setSegmentTimes(passage)

        this.duration = passageVideo.computedDuration

        this.passageVideo = passageVideo
        this.timeline.adjustZoomLimits(true, this.currentTime, this.duration)

        // request download of all note videos for this passage video
        for (const note of passageVideo.notes) {
            for (const item of note.items) {
                if (item.url) {
                    await VideoCache.implicitVideoDownload(item.url)
                }
            }
        }

        this.setCurrentTime(0)

        this.emit('setPassageVideo')
    }

    /** Creates a note and updates the local note reference. Throws an error if
     * something went wrong when creating the note.
     */
    async createNoteIfNonexistent() {
        const { passage, note } = this
        if (!passage || !note) return

        const video = passage.findVideo(note._id)
        if (!video) {
            throw Error('No video for note')
        }

        const updatedVideo = await video.addNote(note)
        const updatedNote = updatedVideo.notes.find((n) => note?._id === n._id)

        // We must update the note, since the reference has changed. Other components
        // rely on note, and they will not see changes if we do not update.
        // However, do not update if note dialog is closed, since this will reopen
        // note dialog! Do not update if note is undefined, since this will close
        // note dialog.
        if (note !== undefined && updatedNote !== undefined) {
            this.setNote(updatedNote)
        }
    }

    setDbsRefs(portion: Portion | null) {
        if (!portion) {
            return
        }

        this.passageReferences = this.getPassageReferences()
        this.dbsRefs = this.defaultReferences(portion)
    }

    getPassageReferences() {
        const { passage } = this

        const passageRefs = passage?.references
        if (passageRefs?.length) {
            return passageRefs
        }

        return []
    }

    // Default reference for current time
    defaultReferences(portion: Portion) {
        const { passageVideo, passage, currentTime } = this
        const verseRefs = passage && passageVideo?.getRefRanges(passage, currentTime)
        if (verseRefs?.length) {
            return verseRefs
        }

        const passageRefs = this.getPassageReferences()
        if (passageRefs.length) {
            return passageRefs
        }

        const portionRefs = portion.references
        if (portionRefs.length) {
            return portionRefs
        }

        return []
    }

    setTimelineZoom(zoom: number) {
        this.timeline.setZoomLevel(zoom)
    }

    setNote(note?: PassageNote) {
        this.note = note
        if (note) {
            this.mostRecentNoteIdViewed = note._id
            this.resetCurrentTime(note.time)
        }
    }
}
