import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'
import { useAppRoot } from '../app/RootContext'
import { DownloadButton } from '../utils/Buttons'
import { exportToFile, createLink, getTimeStampWithoutModby, groupBy } from '../utils/Helpers'

interface ProjectsEditorCSVExportButtonProps {
    languageTagsMap: Map<string, string>
    groupRoot?: Root
}

export const ProjectsEditorCSVExportButton: FC<ProjectsEditorCSVExportButtonProps> = ({
    languageTagsMap,
    groupRoot
}) => {
    const { t } = useTranslation()
    const appRoot = useAppRoot()
    const SEPARATOR = `\t`
    const dateFormatter = appRoot.rt?.dateFormatter

    const getLanguageNameAndCode = (languageCode: string) => ({
        code: languageCode,
        fullname: languageTagsMap.get(languageCode) || ''
    })

    const getProjectRow = (group: string | undefined, project: Project, isRootMode: boolean) => {
        const { code, fullname } = getLanguageNameAndCode(project.language || project.projectProfiles?.language || '')

        const { name: projectName, projectRecordingInfo } = project
        const { firstPassageRecordingDate, firstPassageRecordingId, lastPassageRecordingDate, lastPassageRecordingId } =
            projectRecordingInfo || {}

        const baseRow = [
            group ?? '',
            project.getFormattedDisplayName(),
            fullname,
            code,
            firstPassageRecordingId ? createLink({ projectName, itemId: firstPassageRecordingId }) : '',
            firstPassageRecordingDate && dateFormatter
                ? getTimeStampWithoutModby(firstPassageRecordingDate, dateFormatter)
                : '',
            lastPassageRecordingId ? createLink({ projectName, itemId: lastPassageRecordingId }) : '',
            lastPassageRecordingDate && dateFormatter
                ? getTimeStampWithoutModby(lastPassageRecordingDate, dateFormatter)
                : ''
        ]

        const membersByRole = groupBy(project.members, (m) => m.role)
        const adminEmails = membersByRole.admin?.map((member) => member.email).join(', ') || ''

        return isRootMode
            ? [
                  ...baseRow,
                  project.projectProfiles?.status,
                  project.projectProfiles?.partner,
                  project.projectProfiles?.country,
                  project.projectProfiles?.notes
              ]
            : [...baseRow, adminEmails]
    }

    const handleDownloadCSV = () => {
        if (!appRoot?.rts?.length) return

        const headers = groupRoot
            ? [
                  t('Group'),
                  t('Project'),
                  t('projectLanguage'),
                  t('projectLanguageCode'),
                  t('firstPassageRecording'),
                  t('firstPassageRecordingTime'),
                  t('lastPassageRecording'),
                  t('lastPassageRecordingTime'),
                  t('Admin Users')
              ]
            : [
                  t('Group'),
                  t('Project'),
                  t('projectLanguage'),
                  t('projectLanguageCode'),
                  t('firstPassageRecording'),
                  t('firstPassageRecordingTime'),
                  t('lastPassageRecording'),
                  t('lastPassageRecordingTime'),
                  t('projectProfilesStatus'),
                  t('projectProfilesPartner'),
                  t('projectProfilesCountry'),
                  t('projectProfilesNotes')
              ]

        const rows = (
            groupRoot
                ? appRoot.rts.filter(({ project }) => groupRoot.groupProjects?.includes(project.name))
                : appRoot.getAllProjectRootsSorted()
        )
            .sort(appRoot.sortRootProjects)
            .map(({ group, project }) => getProjectRow(group, project, !groupRoot))

        const tsvContent = [headers, ...rows].map((row) => row.join(SEPARATOR)).join('\n')
        const blob = new Blob([tsvContent], { type: 'text/tab-separated-values;charset=utf-8;' })

        exportToFile(blob, 'AVTT-projects', '.csv')
    }

    return (
        <DownloadButton
            className={groupRoot ? 'csv-export-button group-projects-csv-export-button' : 'csv-export-button'}
            enabled
            onClick={handleDownloadCSV}
            tooltip={t('exportAsCSV')}
        />
    )
}
