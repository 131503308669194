import { FC } from 'react'

interface Props {
    index: number
}

const PassageNoteSegment: FC<Props> = ({ index }) => {
    return <div className="passage-notes-viewer__segment">{index + 1}</div>
}

export default PassageNoteSegment
