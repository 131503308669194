/* eslint-disable react/jsx-props-no-spreading */
import { Component } from 'react'

import { t } from 'i18next'
import { observer } from 'mobx-react'
import { Draggable } from 'react-beautiful-dnd'

import { PassageData } from './types'
import { Root } from '../../models3/Root'
import { DragAndDropIcon } from '../utils/Icons'
import { MemberSelector } from '../utils/MemberDisplay'

import './PassageCard.css'
import './TaskColumn.css'

interface IPassageCard {
    rt: Root
    passageData: PassageData
    index: number
    updateDifficulty: (difficulty: number) => void
    isDragDisabled?: boolean
}

class PassageCard extends Component<IPassageCard> {
    render() {
        const { rt, index, passageData, isDragDisabled } = this.props
        const { project, iAmAdmin, username } = rt
        const { passage, portion } = passageData

        return (
            <Draggable draggableId={passage._id} index={index} isDragDisabled={isDragDisabled}>
                {(provided) => (
                    <div
                        className="task-container"
                        ref={provided.innerRef}
                        data-id={`task-${passage.name}`}
                        {...provided.draggableProps}
                    >
                        <div className="task-title">
                            <div {...provided.dragHandleProps}>
                                {!isDragDisabled && (
                                    <DragAndDropIcon
                                        className="passage-card-drag-handle"
                                        tooltip={t('dragToAnotherTask')}
                                    />
                                )}
                                <div className="task-title-text">{portion.getLongPassageName(passage)}</div>
                            </div>
                        </div>
                        <MemberSelector project={project} passage={passage} enabled={iAmAdmin} currentUser={username} />
                    </div>
                )}
            </Draggable>
        )
    }
}

export default observer(PassageCard)
