import { useCallback } from 'react'

import { MediaFetchPlayer } from './MediaFetchPlayer'
import API from '../../models3/API'

interface ReviewRecordingMediaPlayerProps {
    url: string
    recordedBlob?: Blob
    avttProjectName: string
}

export const ReviewRecordingMediaPlayer = ({ avttProjectName, url, recordedBlob }: ReviewRecordingMediaPlayerProps) => {
    const fetchBlob = useCallback(() => {
        return recordedBlob ? Promise.resolve(recordedBlob) : API.getReviewRecordingBlob(avttProjectName, url)
    }, [avttProjectName, url, recordedBlob])

    return <MediaFetchPlayer fetchBlob={fetchBlob} className="mini-player" />
}
