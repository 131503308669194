import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Root } from '../../models3/Root'
import { RecordingType } from '../../types'
import { uploadRecording } from '../filePickers/BaseRecordingFilePicker'
import { GenericIconButton } from '../utils/Buttons'
import { createAudioTemplate, MINIMUM_VIABLE_OPUS_BITRATE } from '../utils/Opus'

export const AppendSegmentButton = observer(({ rt, isInReview }: { rt: Root; isInReview: boolean }) => {
    const { t, i18n } = useTranslation()
    const dir = i18n.dir()

    const { passage, passageVideo, iAmTranslatorForPassage, isNotPlayingNorRecording } = rt
    const appendSegmentEnabled =
        isNotPlayingNorRecording && iAmTranslatorForPassage(passage) && !isInReview && passageVideo?.isAppendable()

    const appendNewSegment = async () => {
        if (!passage) {
            return
        }

        passage.setCompressionProgressMessage(t('Starting compression...'))
        const durationSeconds = 1
        const template = await createAudioTemplate(durationSeconds)
        await uploadRecording({
            file: template,
            rt,
            passage,
            t,
            audioCompressionOptions: { bitrate: MINIMUM_VIABLE_OPUS_BITRATE },
            recordingType: RecordingType.APPENDED_SEGMENT
        })
        passage.setCompressionProgressMessage('')
    }

    return (
        <GenericIconButton
            iconName={dir === 'rtl' ? 'fa-circle-left' : 'fa-circle-right'}
            className="sl-segment-toolbar-button append-segment-button"
            iconType="far"
            tooltip={t('appendNewSegment')}
            enabled={appendSegmentEnabled}
            onClick={appendNewSegment}
        />
    )
})
