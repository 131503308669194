import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useOnlineStatus } from '../components/app/OnlineStatusContext'
import API from '../models3/API'
import { ProjectProfiles } from '../models3/ProjectEntity'

export const useProjectProfiles = () => {
    const { t } = useTranslation()
    const [inProgress, setInProgress] = useState(false)
    const { checkIsOnline } = useOnlineStatus()

    const updateProjectProfiles = async (projectName: string, projectProfiles: ProjectProfiles) => {
        setInProgress(true)

        try {
            const isOnline = await checkIsOnline()
            if (!isOnline) {
                setInProgress(false)
                throw Error(t('projectProfilesNoInternetError'))
            }

            await API.updateProjectProfiles(projectName, projectProfiles)
        } catch (e) {
            setInProgress(false)
            throw Error(t('projectProfilesError'))
        }

        setInProgress(false)
    }
    return { updateProjectProfiles, inProgress }
}

export default useProjectProfiles
