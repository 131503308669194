import { FC, useState } from 'react'

import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import { SortEnd, SortableContainer } from 'react-sortable-hoc'

import { NoPortionsMessage } from './NoPortionsMessage'
import PortionAdder from './PortionAdder'
import PortionEditor from './PortionEditor'
import PortionListItem from './PortionView'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import { ExportSourceType } from '../../types'
import { ExportModal } from '../modals/export/ExportModal'
import { CopyProjectDataModal } from '../modals/project/CopyDataModal'
import { rerankProjectPortions } from '../passages/utils'
import { CopyButton, DownloadButton, GenericIconButton } from '../utils/Buttons'
import { systemError, CustomErrorBoundary } from '../utils/Errors'
import { FirstTimeSetupModal } from '../utils/FirstTimeSetupModal'
import LoadingMessage from '../utils/InitializationMessage'

import './Portion.css'

interface ISortableContainer {
    rt: Root
    portions: Portion[]
    handleRestorePortion: (portion: Portion) => void
}

interface IPortionsEditor {
    rt: Root
}

const SortableList = SortableContainer((props: ISortableContainer) => {
    const { t } = useTranslation()
    const { rt, portions, handleRestorePortion } = props
    const { iAmAdmin } = rt

    return (
        <div>
            {portions.map((portion, index) => (
                <CustomErrorBoundary
                    key={portion._id}
                    fallbackUI={
                        <div
                            className="portion-portion portion-error"
                            title={t('Something went wrong while displaying this portion')}
                        >
                            <b>{`${portion.name}???`}</b>
                        </div>
                    }
                >
                    <PortionListItem
                        disabled={!iAmAdmin}
                        index={index}
                        rt={rt}
                        portion={portion}
                        handleRestorePortion={handleRestorePortion}
                    />
                </CustomErrorBoundary>
            ))}
        </div>
    )
})

export const InitializedPortionsEditor: FC<IPortionsEditor> = observer(({ rt }) => {
    const { t } = useTranslation()
    const { project, useMobileLayout, iAmTranslator, iAmRoot, trashCan, iAmAdmin } = rt
    const [isCopyPortionsModalOpen, setIsCopyPortionsModalOpen] = useState(false)
    const [isExportModalOpen, setIsExportModalOpen] = useState(false)
    const [isAddPortionModalOpen, setIsAddPortionModalOpen] = useState(false)
    const { portions, restorePortion } = project

    const deletedPortions = [...trashCan.items.values()].filter((value) => value instanceof Portion) as Portion[]

    const allItems = iAmRoot ? [...portions, ...deletedPortions] : portions

    const confirmSort = (onConfirm: () => void) => {
        confirmAlert({
            title: t('reorderPortionsTitle'),
            message: t('reorderPortionsMessage'),
            buttons: [
                {
                    label: t('No'),
                    onClick: () => {}
                },
                {
                    label: t('Yes'),
                    onClick: onConfirm
                }
            ]
        })
    }

    const isFirstTimeSetupNeeded = !iAmRoot && iAmTranslator && !portions.length
    const [isFirstTimeSetupModalOpen, setIsFirstTimeSetupModalOpen] = useState(isFirstTimeSetupNeeded)

    // TODO: show this modal on every page
    if (isFirstTimeSetupModalOpen) {
        return <FirstTimeSetupModal setIsModalOpen={setIsFirstTimeSetupModalOpen} />
    }

    const onSortEnd = (result: SortEnd) => {
        const { oldIndex, newIndex } = result

        project.movePortion(project.portions[oldIndex]._id, newIndex).catch(systemError)
    }

    const handleRestorePortion = async (portion: Portion) => {
        try {
            await restorePortion(deletedPortions, portion._id)
        } catch (error) {
            console.error(`Error restoring portion: ${error}`)
        }
    }

    return (
        <div className="portions-editor">
            <h3>{t('Portions')}</h3>
            {project.portions.length > 0 && (
                <div className="portions-menu">
                    <CopyButton
                        onClick={() => setIsCopyPortionsModalOpen(true)}
                        buttonClassName=""
                        className="portions-menu-item"
                        tooltip={t('Copy portions')}
                        enabled
                    />
                    {isCopyPortionsModalOpen && (
                        <CopyProjectDataModal
                            setOpenModal={setIsCopyPortionsModalOpen}
                            source={project}
                            copyDataType="portions"
                        />
                    )}

                    <DownloadButton
                        className="portions-menu-item"
                        tooltip={t('Export')}
                        onClick={() => setIsExportModalOpen(true)}
                        enabled
                    />

                    {isExportModalOpen && (
                        <ExportModal
                            rt={rt}
                            exportSourceType={ExportSourceType.PROJECT}
                            setOpen={setIsExportModalOpen}
                        />
                    )}
                </div>
            )}

            {isAddPortionModalOpen && (
                <PortionEditor rt={rt} setEditing={setIsAddPortionModalOpen} save={rt.saveNewPortion} />
            )}

            {portions.length === 0 && <NoPortionsMessage allowedToEdit={iAmTranslator} />}
            <SortableList
                portions={allItems}
                rt={rt}
                useDragHandle
                onSortEnd={onSortEnd}
                distance={5}
                handleRestorePortion={handleRestorePortion}
            />
            <div className="portion-list-buttons">
                {!useMobileLayout && iAmTranslator && <PortionAdder onClick={() => setIsAddPortionModalOpen(true)} />}
                {iAmAdmin && portions.length > 0 && (
                    <GenericIconButton
                        enabled
                        onClick={() => confirmSort(() => rerankProjectPortions(project))}
                        className="passage-list-button"
                        tooltip={t('reorderPortionsByReference')}
                        iconName="fa-arrow-down-1-9"
                        iconType="fas"
                    />
                )}
            </div>
        </div>
    )
})

export const PortionsEditor: FC<IPortionsEditor> = observer(({ rt }) => {
    const { initialized, loadingMessage } = rt

    if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

    return <InitializedPortionsEditor rt={rt} />
})
