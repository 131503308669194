import { useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useAppRoot } from '../../app/RootContext'
import { PassageNameEditor } from '../../passages/PassageNameEditor'
import { Switch } from '../../utils/Switch'
import { ModalFooter } from '../Modals'

import '../Modals.css'

interface CopyRecordingToNewPassageModalProps {
    setOpen: (value: boolean) => void
    onOK: (options: { preserveSegmentation: boolean; copyPassageResources: boolean; passageName: string }) => void
}

export const CopyRecordingToNewPassageModal = ({ setOpen, onOK }: CopyRecordingToNewPassageModalProps) => {
    const [preserveSegmentation, setPreserveSegmentation] = useState(true)
    const [copyPassageResources, setCopyPassageResources] = useState(false)
    const [passageName, setPassageName] = useState('')
    const [nameError, setNameError] = useState('')
    const { t } = useTranslation()
    const { rt } = useAppRoot()
    if (!rt) {
        return null
    }

    const { portion } = rt
    if (!portion) {
        return null
    }

    const validatePassageName = (newValue: string) => {
        return portion.checkNewPassageName(newValue)
    }

    const isOkEnabled = validatePassageName(passageName) === ''

    return (
        <Modal show onHide={() => setOpen(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('recordingCopyToNewPassage')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PassageNameEditor
                    passageName={passageName}
                    setNameError={setNameError}
                    setPassageName={setPassageName}
                    nameError={nameError}
                    validatePassageName={validatePassageName}
                />
                <div className="modal-toggle">
                    {t('preserveSegmentation')}
                    <Switch
                        value={preserveSegmentation}
                        setValue={setPreserveSegmentation}
                        tooltip={t('preserveSegmentation')}
                    />
                </div>
                <div className="modal-toggle">
                    {t('Copy passage resources?')}
                    <Switch value={copyPassageResources} setValue={(value) => setCopyPassageResources(value)} />
                </div>
            </Modal.Body>
            <ModalFooter
                enabledOK={isOkEnabled}
                onOK={() => {
                    onOK({ preserveSegmentation, copyPassageResources, passageName })
                    setOpen(false)
                }}
                onCancel={() => setOpen(false)}
            />
        </Modal>
    )
}
