import { observer } from 'mobx-react'

import StatusBoard from './StatusBoard'
import { FilteredPortionPassages } from './StatusEditor'
import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import ProjectDataFilter from '../utils/ProjectDataFilter'

import './StatusBoardEditor.css'

interface IStatusEditor {
    rt: Root
    currentSelectedOption: string
    portionPassages: FilteredPortionPassages[]
    setCurrentPortion: (value: Portion) => void
    setCurrentPassage: (portion: Portion, passage: Passage) => void
    setShowAll: () => void
    setShowAssignedPassages: () => void
    setShowCurrentPortion: () => void
    setShowCurrentPassage: () => void
}

const StatusBoardEditor = observer(
    ({
        rt,
        currentSelectedOption,
        portionPassages,
        setShowAll,
        setShowAssignedPassages,
        setShowCurrentPassage,
        setShowCurrentPortion,
        setCurrentPassage,
        setCurrentPortion
    }: IStatusEditor) => {
        const { project, iAmTranslator } = rt
        const { plans } = project

        if (!plans.length) {
            return null
        }

        const plan = plans[0]
        return (
            <div className="status-board-editor">
                <div className="status-board-editor-header">
                    <div className="portion-selector-container">
                        <div>
                            <ProjectDataFilter
                                iAmTranslator={iAmTranslator}
                                portions={project.portions}
                                currentValue={currentSelectedOption}
                                setShowAll={setShowAll}
                                setShowAssignedPassages={setShowAssignedPassages}
                                setShowCurrentPassage={setShowCurrentPassage}
                                setShowCurrentPortion={setShowCurrentPortion}
                                setPortion={setCurrentPortion}
                                setPassage={setCurrentPassage}
                            />
                        </div>
                    </div>
                </div>
                <div className="status-board">
                    <StatusBoard projectPlan={plan} portionPassages={portionPassages} rt={rt} />
                </div>
            </div>
        )
    }
)

export default StatusBoardEditor
