import { Fragment } from 'react'

import { useTranslation } from 'react-i18next'

import { GenericIcon } from './Icons'

import './WarningList.css'

type WarningListItem = {
    text: string
    key: string
}

type WarningListProps = {
    warnings: WarningListItem[]
}

export const WarningList = ({ warnings }: WarningListProps) => {
    const { t } = useTranslation()

    return (
        <div className="warning-list">
            {warnings.map(({ text, key }) => (
                <Fragment key={key}>
                    <GenericIcon
                        iconName="fa-exclamation-triangle"
                        className="warning-list-warning-icon"
                        tooltip={t('warning')}
                    />
                    {text}
                </Fragment>
            ))}
        </div>
    )
}
