import { t } from 'i18next'
import { observable, computed } from 'mobx'
import _ from 'underscore'

import { DBObject } from './DBObject'
import { IDB } from './IDB'
import { Passage, PassageContentTypes } from './Passage'
import { Project } from './Project'
import { move, remove, restore } from './Utils'
import { Limits } from '../components/app/slttAvtt'
import { getBookTexts } from '../components/passages/utils'
import { RefRange } from '../resources/RefRange'
import { AVTTError, ExportTextFormat, ExportTextType, RecordingMediaType } from '../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:SegmentPositionDialog')

export class Portion extends DBObject {
    @observable name = ''

    @observable rank = ''

    @observable _rev = 0

    @observable passages: Passage[]

    @observable references: RefRange[] = []

    @observable copiedFromId = '' // portion was copied from another portion. e.g. <projectName>/<_id>

    @computed get trackedProjectName() {
        if (this.copiedFromId.indexOf('/') >= 0) {
            return this.copiedFromId.slice(0, this.copiedFromId.indexOf('/'))
        }
        return ''
    }

    @computed get trackedPortionId() {
        return this.copiedFromId.slice(this.copiedFromId.indexOf('/') + 1)
    }

    constructor(_id: string, db?: IDB) {
        super(_id, db)
        this.passages = []
    }

    toDocument() {
        const { name, rank, references, copiedFromId } = this
        const serializedReferences = JSON.stringify(references)
        return this._toDocument({ name, rank, references: serializedReferences, copiedFromId })
    }

    copy() {
        let copy = new Portion(this._id, this.db)
        copy = Object.assign(copy, this)
        copy.passages = this.passages.map((pas) => pas.copy())
        copy.references = this.references.map((ref) => ref.copy())
        return copy
    }

    toSnapshot() {
        return this.toDocument()
    }

    async setName(name: string) {
        if (name.trim() === this.name) {
            return
        }
        const doc = this._toDocument({ name })
        await this.db.put(doc)
    }

    async setRank(rankNumber: number) {
        const doc = this._toDocument({ rank: DBObject.numberToRank(rankNumber) })
        await this.db.put(doc)
    }

    adjustName(name: string) {
        let newName = name
        let number = 0
        while (this.passages.find((passage) => passage.name === newName)) {
            number++
            newName = `${newName} #${number}`
        }
        return newName
    }

    createPassage(passageName: string, checkDuplicate = true) {
        const name = passageName.trim()

        if (checkDuplicate) {
            if (this.passages.find((p) => p.name === name)) {
                throw Error(t('Duplicate name'))
            }
        }

        const newId = this.db.getNewId(this.passages, new Date(Date.now()))
        const passage = new Passage(`${this._id}/${newId}`, this.db)
        passage.name = this.adjustName(name)

        let _rank = 100
        if (this.passages.length > 0) {
            _rank = this.passages.slice(-1)[0].rankAsNumber + 100
        }
        passage.rank = DBObject.numberToRank(_rank)

        return passage
    }

    createPassageFromExisting(passage: Passage) {
        const name = this.adjustName(passage.name)
        const newPassage = this.createPassage(name)
        const copy = passage.copy()
        copy._id = newPassage._id
        copy.name = newPassage.name
        copy.rank = newPassage.rank
        copy.documents = []
        copy.videos = []
        return copy
    }

    async saveNewPassage(passage: Passage) {
        if (this.passages.length + 1 > Limits.MAX_PASSAGES_PER_PORTION) {
            throw new Error(AVTTError.LIMITS_MAX_PASSAGES_PER_PORTION)
        }
        await this.db.put(passage.toDocument())
    }

    async addPassageFromExisting(passage: Passage) {
        await this.saveNewPassage(passage)
        const _passage = _.findWhere(this.passages, { _id: passage._id })
        if (!_passage) throw Error('could not find newly created Passage')
        return _passage
    }

    async addPassage(name: string) {
        const passage = this.createPassage(name)
        await this.saveNewPassage(passage)
        return _.findWhere(this.passages, { _id: passage._id })
    }

    addNewPassage = async ({
        name,
        references,
        checkDuplicate,
        difficulty,
        recordingMediaType,
        savedContentType
    }: {
        name: string
        references?: RefRange[]
        checkDuplicate?: boolean
        savedContentType?: PassageContentTypes
        difficulty?: number
        recordingMediaType?: RecordingMediaType
    }) => {
        const newPassage = this.createPassage(name, checkDuplicate)
        if (references !== undefined) {
            newPassage.references = references
        }
        if (difficulty !== undefined) {
            newPassage.difficulty = difficulty
        }
        if (savedContentType !== undefined) {
            newPassage.contentType = savedContentType
        }
        if (recordingMediaType !== undefined) {
            newPassage.recordingMediaType = recordingMediaType
        }
        const savedPassage = await this.addPassageFromExisting(newPassage)
        return savedPassage
    }

    videod() {
        return this.passages.some((passage) => passage.videod())
    }

    async removePassage(_id: string) {
        await remove(this.passages, _id)
    }

    async restorePassage(trashCanPassages: Passage[], _id: string) {
        await restore(trashCanPassages, _id)
    }

    async movePassage(_id: string, i: number) {
        const idx = _.findIndex(this.passages, { _id })
        if (idx === i) {
            log('movePassage nothing to do')
            return /* nothing to do */
        }

        if (idx === -1) throw Error(`movePassage: _id not found [${_id}]`)

        await move(this.passages, idx, i)
    }

    getDefaultPassage(_id: string | null) {
        const { passages } = this
        let passage: Passage | undefined
        if (_id) {
            passage = _.findWhere(passages, { _id })
        }
        if (!passage && passages.length > 0) {
            passage = passages[0]
        }

        return passage
    }

    checkNewPassageName(name: string) {
        name = name.trim()
        if (_.findWhere(this.passages, { name })) {
            return t('Duplicate name')
        }
        if (name === '') {
            return t('Empty name')
        }

        return ''
    }

    async setReferences(refs: RefRange[]) {
        const serializedReferences = JSON.stringify(refs)
        if (JSON.stringify(this.references) === serializedReferences) {
            return
        }

        const doc = this._toDocument({ references: serializedReferences })
        await this.db.put(doc)
    }

    unviewedVideoExists(username: string, cutoff: Date) {
        const unviewedVideos = this.passages
            .map((passage) => passage.firstUnviewedVideo(username, cutoff))
            .filter((video) => video !== null)
        return unviewedVideos.length > 0
    }

    unviewedNoteExists(username: string, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        const unviewedNotes = this.passages
            .map((passage) => passage.firstUnviewedNote(username, cutoff, includeConsultantOnlyNotes))
            .filter((note) => note !== null)
        return unviewedNotes.length > 0
    }

    unresolvedNoteExists(cutoff: Date, includeConsultantOnlyNotes: boolean) {
        const unresolvedNotes = this.passages
            .map((passage) => passage.firstUnresolvedNoteOnLatestVideo(cutoff, includeConsultantOnlyNotes))
            .filter((note) => note !== null)
        return unresolvedNotes.length > 0
    }

    getBackTranslation(project: Project, exportTextFormat?: ExportTextFormat) {
        const { passages } = this
        return getBookTexts({
            passages,
            project,
            exportTextType: ExportTextType.BACK_TRANSLATION,
            exportTextFormat
        })
    }

    getTranscription(project: Project, exportTextFormat?: ExportTextFormat) {
        const { passages } = this
        return getBookTexts({
            passages,
            project,
            exportTextType: ExportTextType.TRANSCRIPTION,
            exportTextFormat
        })
    }

    getLongPassageName(passage: Passage) {
        return `${this.name} / ${passage.name}`
    }
}
